import isNil from 'lodash/isNil';
import Device from '../../../../utils/Device';

import './style.css';

class DragScroll {
  constructor(target, onScroll = () => { }) {
    this.initEl = this.initEl.bind(this);
    this.getEl = this.getEl.bind(this);
    this.willUnmount = this.willUnmount.bind(this);
    this.handleMouseMove = this.handleMouseMove.bind(this);
    this.handleMouseDown = this.handleMouseDown.bind(this);
    this.handleMouseUp = this.handleMouseUp.bind(this);

    this.onScroll = onScroll;

    this.init = false;
    this.initEl(target);
  }

  initEl(target) {
    if (target && this.el !== target) {
      this.el = target;
      if (isNil(Device.mobile())) {
        if (!this.init) {
          this.init = true;
          this.el.addEventListener('mousedown', this.handleMouseDown);
          window.addEventListener('mousemove', this.handleMouseMove);
          window.addEventListener('mouseup', this.handleMouseUp);
        }
      }
    }
  }

  getEl(evt) {
    const none = evt.target.closest(".MuiDataGrid-cell-content");
    const el = evt.target.closest(".MuiDataGrid-dragscroll");
    if (el && !none) {
      if (!this.el.dataset.last) {
        this.el.dataset.last = [-1, -1];
      }
      return this.el;
    }
    return false;
  }

  willUnmount() {
    if (this.el) {
      this.el.removeEventListener('mousedown', this.handleMouseDown);
    }
    window.removeEventListener('mousemove', this.handleMouseMove);
    window.removeEventListener('mouseup', this.handleMouseUp);
  }

  handleMouseMove(evt) {
    if (this.el && this.el.dataset.state === 'true') {
      const last = this.el.dataset.last.split(',');
      const diffX = last[0] - evt.clientX;
      const diffY = last[1] - evt.clientY;
      this.el.scrollLeft += diffX;
      this.el.scrollTop += diffY;
      this.el.dataset.last = [evt.clientX, evt.clientY];
      this.onScroll(this.el);
    }
  }

  handleMouseUp(evt) {
    this.el.dataset.state = false;
    this.el.dataset.last = [-1, -1];
  }

  handleMouseDown(evt) {
    const el = this.getEl(evt);
    if (el) {
      el.dataset.state = true;
      el.dataset.last = [evt.clientX, evt.clientY];
    }
  }
}

export default DragScroll;