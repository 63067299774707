import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import Button from '@mui/material/Button';
import LinearProgress from '@mui/material/LinearProgress';

import EditableList from '../../containers/EditableList';
import { renderPreviewCell, renderDesignationCellLite, dateTimeEnToDateTimeFr } from '../../utils/ColumnFormatter';
import { parkageProductUrl } from '../../utils/URL';

import Ajax from "../../utils/Ajax";
import Notify from "../../components/base/Notify";
import styles from './styles.js';
import { Chip, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, IconButton,
  InputLabel, MenuItem, Select, TextField, Tooltip, Typography
} from '@mui/material';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import AssessmentIcon from '@mui/icons-material/Assessment';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import LockOpenOutlinedIcon from '@mui/icons-material/LockOpenOutlined';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import Download from '../../utils/Download';
import Confirm from '../../components/base/Confirm';

const filter = createFilterOptions();
const amazonStatusMatch = [
  { value: "Non matché", choice: [] },
  { value: "Matchs multiple", choice: ["Non matché"] },
  { value: "À vérifier", choice: ["Vérifié", "Non matché", "En attente"] },
  { value: "En attente", choice: ["Vérifié", "À vérifier", "Non matché"] },
  { value: "Vérifié", choice: ["Non matché", "En attente"] }
];

class Products extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      optionList: [],
      form: {},
      selectedRows: [],
      productToUpdate: [],
      comment: '',
      missingComment: false,
      updating: false,
      refreshToken: 0,
      valueAsin: {},
      reportsModal: false,
      dialogOpen: false,
      valueSelect: {},
      dialogSelectOpen: false,
      dialogOnSell: { open: false, id: '', prices: [], loading:false },
      dialogChangeStatus: {open: false, data:[], loading: false },
      dialogCreateOffer: {open: false, data:[], loading: false },
      marketplaces: [],
      nomenclatures: [],
    }
    this.renderReportButton = this.renderReportButton.bind(this);
    this.renderIdCell = this.renderIdCell.bind(this);
    this.renderAmazonStatus = this.renderAmazonStatus.bind(this);
    this.lockRenderCell = this.lockRenderCell.bind(this);
    this.amazonActionRenderCell = this.amazonActionRenderCell.bind(this);
    this.handleAmazonLockUnlockSync = this.handleAmazonLockUnlockSync.bind(this);
    this.handleBulkChangeAmazonVerified = this.handleBulkChangeAmazonVerified.bind(this);
    this.handleBulkChangeAmazonStatus = this.handleBulkChangeAmazonStatus.bind(this);
    this.handleBulkChangeAmazonNoMatch = this.handleBulkChangeAmazonNoMatch.bind(this);
    this.handleGetPrices = this.handleGetPrices.bind(this);
    this.handleSellAll = this.handleSellAll.bind(this);
    this.handleUpdateStatus = this.handleUpdateStatus.bind(this);
    this.getOptionLabelBool = this.getOptionLabelBool.bind(this);
    this.getOptionNomenclature = this.getOptionNomenclature.bind(this);
  }

  asyncSetState(params) {
    return new Promise(resolve => {
      this.setState(params, resolve);
    });
  }

  async componentDidMount() {
    const { marketplaces, nomenclatures } = await Ajax.get({ url: `api/product_amazon/options`, token: true });
    this.setState({ marketplaces, nomenclatures });
  }

  async handleBulkChangeAmazonStatus(data = []) {
    if (data.length === 0) {
      Notify.info('Aucune donnée à modifier');
      return;
    }
    this.setState({ dialogChangeStatus: { open: true, data: data, loading: false } });
  }

  async onValidateChangeStatus() {
    await this.asyncSetState(prev => ({ ...prev, dialogChangeStatus: { ...prev.dialogChangeStatus, loading: true } }));
    const { data } = this.state.dialogChangeStatus;
    const res = await Ajax.patch({
      url: `api/product_amazon/update`,
      token: true,
      body: { data }
    });
    if (res.type === "success") {
      Notify.success('Changement de status ok');
      this.setState(prev => ({ refreshToken: prev.refreshToken + 1, dialogChangeStatus: { open: false, data: [], loading: false } }));
    } else {
      Notify.error('Erreur dans les changements de status');
      this.setState({ dialogChangeStatus: { open: false, data: [], loading: false } });
    }
  }

  async onValidateCreateOffer() {
    await this.asyncSetState(prev => ({ ...prev, dialogCreateOffer: { ...prev.dialogCreateOffer, loading: true, progress: 'En cours de progression 0%' } }));
    const { data } = this.state.dialogCreateOffer;
    const res = await Ajax.post({
      url: `api/product_amazon/create_offers`,
      token: true,
      body: { ids: data },
      onEvent: resultProgress => {
        if (resultProgress?.eventType === "SUCCESS") {
          this.setState(prev => ({ ...prev, dialogCreateOffer: { ...prev.dialogCreateOffer, loading: true, progress: 'Finalisation' } }));
        } else if (resultProgress?.eventType === "PROGRESS" && resultProgress?.step === 2) {
          this.setState(prev => ({ ...prev, dialogCreateOffer: { ...prev.dialogCreateOffer, loading: true, progress: `En cours de progression ${(resultProgress?.progress * 100).toFixed(2) }%` }}));
        } else if (resultProgress?.eventType === "GENERATE REPORT") {
          this.setState(prev => ({ ...prev, dialogCreateOffer: { ...prev.dialogCreateOffer, loading: true, progress: 'Création du rapport' } }));
        }
      },
    });
    if (res.type === "success") {
      if (res?.result?.status === 'Nothing to update') {
        Notify.info('Aucun produit n\'a été mis en vente');
      } else if (res?.result?.status === 'Already running') {
        Notify.info('Une autre synchronisation est en cours, veuillez patienter');
      } else {
        Notify.success('Les produits ont été mis en vente, consultez le rapport pour connaitre le détail');
      }
      this.setState(prev => ({ refreshToken: prev.refreshToken + 1, dialogCreateOffer: { open: false, data: [], loading: false } }));
    } else {
      Notify.error('Erreur dans la mise ne vente des produits');
      this.setState({ dialogCreateOffer: { open: false, data: [], loading: false } });
    }
  }

  async handleBulkChangeAmazonVerified(rows) {
    const check = rows.filter((item) => item.amazon_state_status === "À vérifier" && item.visibility === 1 && item.price > 0 && item.amazon_lock_sync === 0);
    const data = check.map((item) => ({
      id: item.id, asin: item.amazon_asin, name: item.amazon_product_name,
      productType: item.amazon_product_type, image: item.amazon_image_url, status: "Vérifié"
    }));
    this.handleBulkChangeAmazonStatus(data);
  }

  async handleBulkChangeAmazonNoMatch(rows) {
    const check = rows.filter((item) => item.amazon_state_status !== "Non matché");
    const data = check.map((item) => ({
      id: item.id, asin: '', name: '',
      productType: '', image: '', status: "Non matché"
    }));
    this.handleBulkChangeAmazonStatus(data);
  }

  async handleUpdateStatus(row, option, status) {
    const data = [{
      id: typeof option.id !== "undefined" ? option.id : row.id,
      asin: typeof option.asin !== "undefined" ? option.asin : row.amazon_asin,
      name: typeof option.name !== "undefined" ? option.name : row.amazon_product_name,
      image: typeof option.image !== "undefined" ? option.image : row.amazon_image_url,
      productType: typeof option.productType !== "undefined" ? option.productType : row.amazon_product_type,
      status: status
    }];
    this.handleBulkChangeAmazonStatus(data);
  }

  async handleSellAll(rows) {
    const ids = [];
    rows.forEach((item) => {
      if (item.amazon_state_status === "Vérifié" && item.amazon_sync === 0 && item.amazon_lock_sync === 0) {
        ids.push(item.id);
      } 
    });
    if (ids.length === 0) {
      Notify.info('Aucun produit à mettre en vente');
      return;
    }
    this.setState({ dialogCreateOffer: { open: true, data: ids, loading: false } });
  }

  async handleCreateOfferAmazon(row, priceList) {
    //sku, asin, stock, price, productType
    await this.asyncSetState(prev => ({ ...prev, dialogOnSell: { ...prev.dialogOnSell, loading: true }}));
    const data = {
      asin: row.amazon_asin,
      stock: row.stock,
      price: priceList,
      productType: row.amazon_product_type
    }
    const res = await Ajax.post({
      url: `api/product_amazon/create_offers/` + row.id,
      token: true,
      body: { ...data }
    });
    if (res.type === "success") {
      Notify.success('Produit mis en vente');
      this.setState(prev => ({ refreshToken: prev.refreshToken + 1, dialogOnSell: { open: false, id: '', prices: [] } }));
    } else {
      Notify.error('Erreur lors de la mise en vente');
      this.setState({ dialogOnSell: { open: false, id: '', prices: [] } });
    }
  }

  async handleAmazonLockUnlockSync(id, lock) {
    const res = await Ajax.get({
      url: `api/product_amazon/${id}/${lock ? "lock" : "unlock"}`,
      token: true,
    });
    if (res.type === "success") {
      this.setState(prev => ({ refreshToken: prev.refreshToken + 1 }));
      return res.item
    } else {
      return false;
    }
  }

  async handleGetCatalogProductByAsin(asin) {
    const res = await Ajax.get({
      url: `api/product_amazon/` + asin,
      token: true,
    });
    if (res.type === "success") {
      return res.item
    } else {
      return false;
    }
  }

  async handleGetPrices(productIds) {
    const { marketplaces } = this.state
    const countryCodes = marketplaces.map(({ country_code }) => country_code);
    const res = await Ajax.post({
      url: `api/product_amazon/get_prices`,
      token: true,
      body: { productIds: productIds, countryCodes: countryCodes }
    });
    if (res.type === "success") {
      return res.data
    } else {
      return false;
    }
  }

  renderSetAmazonStatus() {
    const { open, data, loading } = this.state.dialogChangeStatus;
    return (
      <Confirm
        open={open}
        title="Changement de status"
        message={loading ? `Chargement en cours, veuillez patienter....` : `Voulez vous changer le status de ces ${data.length} élément(s) ?`}
        onValidate={loading ? null : () => this.onValidateChangeStatus()}
        onClickAway={() => this.setState({ dialogChangeStatus: { open: false, data: [], loading: false } })}
      />
    );
  }

  renderCreateOffer() {
    const { open, data, loading, progress } = this.state.dialogCreateOffer;
    return (
      <Confirm
        open={open}
        title="Mise en vente de produits"
        message={loading ? `Chargement en cours, veuillez patienter : ${progress || 'Initialisation....'}` : `Voulez vous mettre en vente ces ${data.length} produit(s) ?`}
        onValidate={loading ? null : () => this.onValidateCreateOffer()}
        onClickAway={() => this.setState({ dialogCreateOffer: { open: false, data: [], loading: false } })}
      />
    );
  }

  renderIdCell({ value }) {
    const url = parkageProductUrl(value);
    return <Button component="a" href={url} variant="text" color="inherit" target="_blank">{value}</Button>;
  }

  renderAsinCell({ value }) {
    const url = "https://www.amazon.fr/dp/" + value;
    return <Button component="a" href={url} variant="text" color="inherit" target="_blank">{value}</Button>;
  }

  dialogConfirmProduct(row, valueAsin, dialogOpen) {
    return (
      <Dialog open={dialogOpen && row.row.id === valueAsin.id} onClose={() => { this.setState({ dialogOpen: false, dialogSelectOpen: false, valueSelect: {}, valueAsin: {} }) }}>
        <DialogTitle>{valueAsin.status === 'Non matché' ? "Retirer" : "Confirmer"} un produit Amazon</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {valueAsin.status === 'Non matché' ? "Retirer" : "Confirmer"} le produit {row.row.id} ?
          </DialogContentText>
          <div style={{ display: 'flex', justifyContent: 'space-evenly', alignItems: 'center' }}>
            {valueAsin?.image && <img alt={valueAsin?.asin} src={valueAsin?.image} style={{ width: 200, height: 'auto', padding: 10 }} />}
            <div style={{ width: '70%', height: '100%' }}>
              <b><a href={`https://www.amazon.fr/dp/${valueAsin?.asin}`} style={{ color: 'inherit' }} target="_blank" rel="noreferrer">{valueAsin?.asin}</a></b><br />
              <p style={{ width: '100%' }}>{valueAsin?.name}</p>
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => this.setState({ dialogOpen: false, dialogSelectOpen: false, valueSelect: {}, valueAsin: {} })}>Annuler</Button>
          <Button
            type="submit"
            onClick={async () => {
              await this.handleUpdateStatus(row.row, (valueAsin.status === "Non matché" ? { asin: '', image: '', productType: '', name: '' } : valueAsin), valueAsin.status)
              this.setState({ dialogOpen: false, dialogSelectOpen: false, valueSelect: {}, valueAsin: {} })
            }}
          >
            Oui
          </Button>
        </DialogActions>
      </Dialog>
    )
  }

  async handleReportExports(row) {
    const res = await Ajax.get({
      url: "api/reports/" + row.id + "/export",
      token: true,
    });
    if (res.type === 'success' && res.content && res.content !== 'false') {
      Download(res.content, `export-report-order-amazon-${moment().local().format('YYYY-MM-DD_HH-mm-ss')}.csv`, 'csv');
      Notify.success('Opération effectuée avec succès');
    }
  }

  renderReports() {
    const { classes } = this.props;
    const { reportsModal, refreshToken } = this.state

    return (
      <Dialog maxWidth="xl" sx={{ height: '100%' }} fullWidth onClose={() => this.setState({ reportsModal: !reportsModal })} open={reportsModal}>
        <DialogContent sx={{ minHeight: "33rem" }}>
          <div className={classes.wrapper}>
            <EditableList
              refreshToken={refreshToken}
              title="Rapports de synchronisation"
              route="reports"
              rowKey="id"
              maxSize="sm"
              defaultParams={{
                order: 'report_creation_date',
                direction: 'DESC',
              }}
              disabledHashedState
              queryStringEnrich={() => ({ reportType: ['Amazon Auto Find Asin', 'Amazon Create Offerts'] })}
              columns={[
                { field: 'id', headerName: 'ID', width: 60 },
                {
                  field: '__actions_export', headerName: 'Export', width: 90, sortable: false, renderCell: ({ row }) => (
                    <Tooltip title="Exporter la data">
                      <span>
                        <IconButton onClick={() => this.handleReportExports(row)}>
                          <SaveAltIcon />
                        </IconButton>
                      </span>
                    </Tooltip>
                  )
                },
                { field: 'report_success_total', headerName: 'Réussite', width: 120, sortable: false },
                { field: 'report_failed_total', headerName: 'Echec', width: 80, sortable: false },
                { field: 'report_pending_total', headerName: 'En attente', width: 120, sortable: false },
                { field: 'report_creation_date', headerName: 'Date de création', width: 200, valueFormatter: dateTimeEnToDateTimeFr },
                { field: 'report_type', headerName: 'Type', width: 200 },
              ]}
              filterParams={[
                { key: 'id', label: 'ID' },
                { key: 'reportCreationDateMin', label: 'Date de création min', type: 'date' },
                { key: 'reportCreationDateMax', label: 'Date de création max', type: 'date' },
                { key: 'reportType', label: 'Type de rapport', type: 'select', options: ['', 'Amazon Auto Find Asin', 'Amazon Create Offerts'], getOptionLabel: opt => opt || 'Tout', variant: 'standard' },
              ]}
              searchPlaceholder="ID"
              allowEdit={false}
              allowAdd={false}
              allowDelete={false}
              allowExport={false}
            />
          </div>
        </DialogContent>
      </Dialog>
    )
  }

  renderAmazonStatus(row) {
    const { valueSelect, dialogSelectOpen } = this.state
    const statusColors = {
      'Vérifié': 'green',
      'À vérifier': 'blue',
      'Non matché': 'black',
      'Matchs multiple': 'orange',
      'En attente': 'grey',
    };
    return (
      <>
        {row.row.amazon_state_status !== "Non matché" ?
          <>
            <FormControl sx={{
              width: "100%", verticalAlign: 'unset !important',
              '.MuiOutlinedInput-notchedOutline': { border: 0, },
              '.MuiInputLabel-root': { color: `${statusColors[`${row.row.amazon_state_status}`]} !important` },
              '.MuiFormLabel-root-MuiInputLabel-root.Mui-focused': { color: `${statusColors[`${row.row.amazon_state_status}`]} !important`, },
            }} size="small">
              <InputLabel id="demo-select-small-label">
                {row.row.amazon_state_status}
              </InputLabel>
              <Select
                labelId="demo-select-small-label"
                id="demo-select-small"
                value={""}
                label=""
                onChange={(e) => {
                  this.setState({
                    valueSelect: {
                      id: row.row.id, asin: row.row.amazon_asin, image: row.row.amazon_image_url,
                      productType: row.row.amazon_product_type, name: row.row.amazon_product_name, status: e.target.value
                    }, dialogSelectOpen: true
                  })
                }}
              >
                {amazonStatusMatch.find((item) => item.value === row.row.amazon_state_status)?.choice.map((item) =>
                  <MenuItem key={item + row.row.id} value={item}>
                    {item}
                  </MenuItem>
                )}
              </Select>
            </FormControl>
            {this.dialogConfirmProduct(row, valueSelect, dialogSelectOpen)}
          </>
          : <p style={{ color: statusColors[`${row.row.amazon_state_status}`], fontWeight: '400' }}>{row.row.amazon_state_status}</p>
        }
      </>
    );
  }

  renderActionOnSell(row) {
    const { dialogOnSell, marketplaces } = this.state
    return (
      <>
        <Button
          onClick={async () => {
            const res = await this.handleGetPrices([row.id])
            if (res[`${row.id}`]) {
              const price = []
              marketplaces.map((item) => {
                price.push({ country: item.country, price: res[`${row.id}`][item.country_code] })
                return price
              })
              this.setState({ dialogOnSell: { prices: price, open: true, id: row.id } })
            } else Notify.error("Aucun prix trouvé")
          }}
          variant="text"
          color="inherit"
        >
          Mettre en vente
        </Button>
        <Dialog fullWidth open={dialogOnSell.open && row.id === dialogOnSell.id} onClose={() => {
          this.setState({
            dialogOnSell: { open: false, id: '', prices: [] }
          })
        }}>
          {dialogOnSell.loading ? <LinearProgress /> : null}
          <DialogTitle sx={{ display: 'flex', alignContent: 'center' }}>
            <img alt={row.id + "_image"} src={row.amazon_image_url} style={{ width: '60px', marginRight: 10 }} />
            <p>Mettre en vente ce produit sur Amazon</p>
          </DialogTitle>
          <DialogContent sx={{ width: '100%' }}>
           
            <Typography><b>Nom du produit</b>: {row.amazon_product_name}</Typography>
            <Typography><b>Id du produit</b>: {row.id}</Typography>
            <Typography><b>Asin Amazon</b>: {row.amazon_asin}</Typography>
            <Typography><b>Catégorie</b>: {row.amazon_product_type}</Typography>
            {dialogOnSell.prices.length > 0 &&
              <>
                <DialogContentText>Prix par pays:</DialogContentText>
                <div style={{ display: 'flex', width: '100%', flexWrap: 'wrap' }}>
                  {dialogOnSell.prices.map((value, index) =>
                    <Chip
                      sx={{ margin: '3px', padding: '5px' }}
                      key={index}
                      label={value.country + ": " + value?.price}
                    />
                  )}
                </div>
              </>
            }
          </DialogContent>
          <DialogActions>
            <Button disabled={dialogOnSell.loading}  onClick={() => this.setState({ dialogOnSell: { open: false, id: '', prices: [] } })}>Annuler</Button>
            <Button disabled={dialogOnSell.loading} type="submit" onClick={() => { this.handleCreateOfferAmazon(row, dialogOnSell.prices) }}>Oui</Button>
          </DialogActions>
        </Dialog>
      </>
    )
  }

  amazonActionRenderCell(row) {
    const { valueAsin, dialogOpen } = this.state
    const match = (row.row.amazon_state_status === "Matchs multiple" && JSON.parse(row.row.amazon_state_status_detail)) || []
    var value = {}
    return (
      <div style={{ width: '100%' }}>
        {row.row.amazon_state_status !== "Vérifié" && row.row.amazon_lock_sync === 0 &&
          <>
            <Autocomplete selectOnFocus clearOnBlur autoHighlight handleHomeEndKeys id="tags-standard" options={match} value={value}
              sx={{ m: 1, '.MuiOutlinedInput-notchedOutline': { border: 0, '&:hover': { border: 0 } } }}
              renderInput={(params) => <TextField {...params} label="Choisir ou entrer un Asin" />}
              onChange={async (event, newValue) => {
                if (newValue && newValue !== '') {
                  if (typeof newValue === 'string') {
                    // timeout to avoid instant validation of the dialog's form.
                    this.setState({
                      valueAsin: {
                        id: row.row.id, asin: newValue.asin, name: newValue.name, image: newValue.image,
                        productType: newValue.productType, status: "Vérifié"
                      },
                      dialogOpen: true
                    });
                  } else if (newValue && newValue.inputValue) {
                    const item = await this.handleGetCatalogProductByAsin(newValue.inputValue)
                    if (item) {
                      this.setState({
                        valueAsin: {
                          id: row.row.id,
                          asin: newValue.inputValue,
                          name: (item.summaries.length > 0 && item.summaries[0].itemName) || '',
                          image: (item.images.length > 0 && item.images[0].images[0].link) || '',
                          productType: (item.productTypes.length > 0 && item.productTypes[0].productType) || '',
                          status: "Vérifié"
                        }, dialogOpen: true
                      });
                    } else Notify.error("Aucun item trouvé avec cet asin");
                  } else {
                    this.setState({
                      valueAsin: {
                        id: row.row.id, asin: newValue.asin, name: newValue.name, image: newValue.image,
                        productType: newValue.productType, status: "Vérifié"
                      },
                      dialogOpen: true
                    });
                  }
                }
              }}
              getOptionLabel={(option) => option?.asin || ''}
              renderOption={(props, option) => (
                <div  {...props} style={{ display: 'flex' }}>
                  {option?.image && <img alt={option?.asin} src={option?.image} style={{ width: 70, marginRight: 20 }} />}
                  <div style={{ width: '70%', height: '100%' }}>
                    <a href={`https://www.amazon.fr/dp/${option?.asin}`} style={{color:'inherit'}} target="_blank" rel="noreferrer">{option?.asin}</a><br />
                    <p style={{ width: '100%' }}>{option?.name}</p>
                  </div>
                </div>
              )}
              filterOptions={(options, params) => {
                const filtered = filter(options, params);
                if (params.inputValue !== '') {
                  filtered.push({
                    inputValue: params.inputValue,
                    asin: `Add "${params.inputValue}"`,
                  });
                }
                return filtered;
              }}
            />
            {this.dialogConfirmProduct(row, valueAsin, dialogOpen)}
          </>
        }
        {(row.row.amazon_state_status === "Vérifié" && row.row.amazon_sync === 0 && row.row.amazon_lock_sync === 0) &&
          <>
            {this.renderActionOnSell(row.row)}
          </>
        }
      </div>
    )
  }

  lockRenderCell(params) {
    return (
      <Tooltip key="Rapport" title={params.value > 0 ? "Synchroniser" : "Stoper la synchronisation"}>
        <span>
          <IconButton color="secondary" onClick={async () => {
            await this.handleAmazonLockUnlockSync(params.id, (params.value > 0))
          }}>
            {params.value > 0 ? <LockOutlinedIcon /> : <LockOpenOutlinedIcon />}
          </IconButton>
        </span>
      </Tooltip>
    )
  }

  renderReportButton() {
    const { reportsModal } = this.state;
    return (
      <Tooltip key="Rapport" title="Voir les rapports">
        <span>
          <IconButton color="secondary" onClick={() => this.setState({ reportsModal: !reportsModal })}>
            <AssessmentIcon />
          </IconButton>
        </span>
      </Tooltip>
    )
  }

  stockRenderCell(params) {
    const style = {};
    const number = parseInt(params.value, 10);
    if (number <= 0) {
      style.color = 'red';
    }
    return <span style={style}>{Number.isNaN(number) ? 'NULL' : number}</span>;
  }

  getOptionLabelBool(opt) {
    if (opt === '1') {
      return 'Oui';
    } else if (opt === '0') {
      return 'Non';
    }
    return 'Tout';
  }

  getOptionNomenclature(id) {
    const { nomenclatures } = this.state;
    const nomenclature = nomenclatures.find(item => item.id === id);
    return nomenclature?.name || '';
  }

  render() {
    const { classes } = this.props;
    const { refreshToken, nomenclatures } = this.state
    return (
      <div className={classes.wrapper}>
        <EditableList
          title="Amazon - Produits"
          route="products"
          searchKey="search"
          refreshToken={refreshToken}
          columns={[
            { field: 'id', headerName: 'ID', width: 90, renderCell: this.renderIdCell },
            { field: 'image_url_1', headerName: 'Aperçu', align: 'center', width: 85, sortable: false, renderCell: renderPreviewCell },
            { field: 'name_fr', headerName: 'Désignation', width: 350, renderCell: renderDesignationCellLite },

            { field: 'amazon_asin', headerName: 'Amazon ASIN', width: 130, renderCell: this.renderAsinCell },
            { field: 'amazon_image_url', headerName: 'Aperçu Amazon', align: 'center', width: 85, sortable: false, renderCell: renderPreviewCell },
            { field: 'amazon_product_name', headerName: 'Nom Amazon', width: 350, sortable: false },

            { field: 'amazon_state_status_detail', headerName: 'Amazon Action', width: 350, sortable: false, renderCell: this.amazonActionRenderCell },
            { field: 'amazon_state_status', headerName: 'Amazon Status', width: 200, renderCell: this.renderAmazonStatus },
            { field: 'amazon_lock_sync', headerName: 'Amazon Lock', width: 120, renderCell: this.lockRenderCell },
            { field: 'amazon_sync', headerName: 'Amazon Sync', width: 120, valueFormatter: ({ value }) => value >= 1 ? 'Oui' : 'Non' },

            { field: 'stock', headerName: 'Stock', align: 'center', width: 100, renderCell: this.stockRenderCell },
            { field: 'visibility', headerName: 'Visible', width: 90, valueFormatter: ({ value }) => value >= 1 ? 'Oui' : 'Non' },
            { field: 'nomenclature_name', headerName: 'Nomenclature', width: 150, },
            { field: 'barcode', headerName: 'Code barre', width: 150, },
          ]}
          filterParams={[
            { key: 'id', label: 'ID' },
            { key: 'barcode', label: 'Code barre' },
            { key: 'visibility', label: 'Visible', type: 'select', options: ['', '0', '1'], getOptionLabel: this.getOptionLabelBool, variant: 'standard' },
            { label: 'Stock', startkey: 'stockStart', endkey: 'stockEnd', type: 'range' },
            { key: 'amazon_asin', label: 'Amazon asin', variant: 'standard' },
            { key: 'hasAsin', label: 'Asin existant', type: 'select', options: ['', '0', '1'], getOptionLabel: this.getOptionLabelBool, variant: 'standard' },
            { key: 'hasBarcode', label: 'Code barre', type: 'select', options: ['', '0', '1'], getOptionLabel: this.getOptionLabelBool, variant: 'standard' },
            { key: 'amazon_sync', label: 'Amazon Sync', type: 'select', options: ['', '0', '1'], getOptionLabel: this.getOptionLabelBool, variant: 'standard' },
            { key: 'amazon_lock_sync', label: 'Amazon Lock', type: 'select', options: ['', '0', '1'], getOptionLabel: this.getOptionLabelBool, variant: 'standard' },
            { key: 'amazon_state_status', label: 'Amazon status', type: 'select', options: ['Vérifié', 'À vérifier', 'Non matché', 'Matchs multiple', 'En attente'], variant: 'standard' },
            { key: 'nomenclature_id', label: 'Nomenclature', type: 'select', options: nomenclatures.map(nomenclature => nomenclature.id), getOptionLabel: this.getOptionNomenclature }
          ]}
          searchPlaceholder="Filtrer par nom, référence, code barre"
          actions={[
            this.renderReportButton(),
          ]}
          rowActions={[
            { key: "handleVerified", label: "Verifier ces produits", onClick: this.handleBulkChangeAmazonVerified },
            { key: "handleNomatch", label: "Enlever la verification de ces produits", onClick: this.handleBulkChangeAmazonNoMatch },
            { key: "handleSellAll", label: "Mettre les produits vérifiés en vente", onClick: this.handleSellAll },
          ]}
          maxSize="xl"
          allowExport={false}
          allowEdit={false}
          allowAdd={false}
          allowDelete={false}
        />
        {this.renderReports()}
        {this.renderSetAmazonStatus()}
        {this.renderCreateOffer()}
      </div>
    );
  }
}

Products.propTypes = {
  classes: PropTypes.object.isRequired,
}
export default withStyles(styles)(Products);
