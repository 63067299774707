import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { debounce } from 'lodash';
import { DataGrid as MuiDataGrid } from '@mui/x-data-grid';
import { renderPreviewCell, renderDesignationCellLite, dateTimeEnToDateTimeFr } from '../../utils/ColumnFormatter';
import EditableList from '../../containers/EditableList';
import { parkageProductUrl } from '../../utils/URL';

import Ajax from "../../utils/Ajax/index.js";
import Notify from "../../components/base/Notify/index.js";
import styles from './styles.js';
import {
  Button, Dialog, DialogContent, FormControl, IconButton, Badge,
  InputLabel, MenuItem, Select, TextField, Tooltip, Typography, InputAdornment, Stack,
} from '@mui/material';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import AssessmentIcon from '@mui/icons-material/Assessment';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import LockOpenOutlinedIcon from '@mui/icons-material/LockOpenOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CheckIcon from '@mui/icons-material/Check';
import Download from '../../utils/Download/index.js';
import Confirm from '../../components/base/Confirm';
import RobotCotCotIcon from './RobotCotCot.js';
import AutoComplete from '../../components/base/Autocomplete/index.js';

const mkmStatusMatch = {
  'Vérifié': { select: ['En attente', 'Non matché'], color: 'green' },
  'À vérifier': { select: ['Vérifié', 'En attente', 'Non matché'], color: 'blue' },
  'Non matché': { select: [], color: 'black' },
  'Matchs multiple': { select: ['Non matché'], color: 'orange' },
  'En attente': { select: ['Vérifié', 'Non matché'], color: 'grey' },
};
const languages = new Map([["en", 1], ["fr", 2], ["de", 3], ["es", 4], ["it", 5], ["cn", 6], ["jp", 7], ["pt", 8], ["ru", 9], ["kr", 10], ["tw", 11]]);
const langTrad = new Map([['', 'Tous'],["en", 'Anglais'], ["fr", 'Français'], ["de", 'Allemand'], ["es", 'Espagnol'], ["it", 'Italien'], ["cn", 'Chinois Simplifié'], ["jp", 'Japonais'], ["pt", 'Portugais'], ["ru", 'Russe'], ["kr", 'Coréen'], ["tw", 'Chinois Traditionnel'], ['nl', 'Néerlandais']]);

const nomenclatureGameId = new Map([[2, 1], [3, 3], [4, 8], [5, 6], [6, 10], [7, 9], [8, 15], [11,11], [12, 13], [13, 17], [16, 18], [18, 19], [19, 21]]);
class Products extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      refreshToken: 0,
      changeStatus: null,
      productIdMap: {},
      multipleMatch: null,
      reportsModal: false,
      nomenclatures: [],
      createOffers: { eventType: 'CLOSE' },
      lastQueryString: {},
      lastRowCount: -1,
      roboCotCotPercent: null,
      openRobotCotCot: false,
      finder: {row: null, options: [], search: '', expansionId: 0 },
      productOptionList: {},
      openMassLock: null,
    }
    this.renderReportButton = this.renderReportButton.bind(this);
    this.renderIdCell = this.renderIdCell.bind(this);
    this.renderStatus = this.renderStatus.bind(this);
    this.renderWebsiteCell = this.renderWebsiteCell.bind(this);
    this.renderFinderWebsiteCell = this.renderFinderWebsiteCell.bind(this);
    this.lockRenderCell = this.lockRenderCell.bind(this);
    this.actionRenderCell = this.actionRenderCell.bind(this);
    this.handleMkmLockUnlockSync = this.handleMkmLockUnlockSync.bind(this);
    this.renderMultipleMatchDialog = this.renderMultipleMatchDialog.bind(this);
    this.getOptionLabelBool = this.getOptionLabelBool.bind(this);
    this.getOptionNomenclature = this.getOptionNomenclature.bind(this);
    this.renderMatchRowFromMulti = this.renderMatchRowFromMulti.bind(this);
    this.handleGetProductByID = this.handleGetSingleByID.bind(this);

    this.handleSellAll = this.handleSellAll.bind(this);
    this.handleMassLock = this.handleMassLock.bind(this);
    this.handleMassUnlock = this.handleMassUnlock.bind(this);

    this.eventSellLastTime = 0;
    this.eventSellLastId = 0;
  }

  asyncSetState(params) {
    return new Promise(resolve => {
      this.setState(params, resolve);
    });
  }

  async componentDidMount() {
    const [nomenclaturesResult, percentResult ] = await Promise.all([
      Ajax.get({ url: `api/product_cm/options`, token: true }),
      Ajax.get({ url: `api/robotcotcot`, token: true })
    ]);
    this.setState({ nomenclatures: nomenclaturesResult.nomenclatures, roboCotCotPercent: percentResult.percent });
    if (percentResult.percent !== -1) {
      this.getRobotCotCotPercent();
    }
  }
  

  /** HANDLERS */

  handleChangeStatus(status, rows) {
    const list = rows.filter(row => !!mkmStatusMatch[row.mkm_state_status] && mkmStatusMatch[row.mkm_state_status].select.indexOf(status) !== -1);
    if(list.length === 0) {
      Notify.info('Aucune donnée à modifier');
      return;
    }
    if (rows.length === 1) {
      this.onValidateChangeStatus(status, rows);
    } else {
      this.setState({ changeStatus: { status, rows } });
    }
  }

  async handleReportExports(row) {
    const res = await Ajax.get({
      url: "api/reports/" + row.id + "/export",
      token: true,
    });
    if (res.type === 'success' && res.content && res.content !== 'false') {
      Download(res.content, `export-report-order-mkm-${moment().format('YYYY-MM-DD_HH-mm-ss')}.csv`, 'csv');
      Notify.success('Opération effectuée avec succès');
    }
  }

  async handleMkmLockUnlockSync(id, lock) {
    const res = await Ajax.get({
      url: `api/product_cm/${id}/${lock ? "unlock" : "lock"}`,
      token: true,
    });
    if (res.type === "success") {
      this.setState(prev => ({ refreshToken: prev.refreshToken + 1 }));
      return res.item
    } else {
      return false;
    }
  }

  async handleChangeProductId(id, productId, toCheck = true) {
    if(productId) {
      const notifyKey = Notify.info('Chargement en cours...', { delay: 0 });
      if (toCheck) {
        const single = await this.handleGetSingleByID(productId);
        if(!single) {
          Notify.close(notifyKey);
          Notify.error('Aucun produit trouvé');
          return;
        }
      }
      const res = await Ajax.patch({ url: `api/product_cm/update_product_id`, token: true, body: { id, productId } });
      Notify.close(notifyKey);
      if (res.type === "success") {
        Notify.success('Changement d\'id ok');
        this.setState(prev => ({ refreshToken: prev.refreshToken + 1 }));
      } else {
        Notify.error('Erreur dans le changement d\'id');
      }
    }
  }

  async onValidateChangeStatus(status, rows) {
    const notifyKey = Notify.info('Chargement en cours...', {delay: 0});
    const ids = rows.map(row => row.id);
    const res = await Ajax.patch({ url: `api/product_cm/update_status`, token: true, body: { ids, status } });
    Notify.close(notifyKey);
    if (res.type === "success") {
      Notify.success('Changement de status ok');
      this.setState(prev => ({ refreshToken: prev.refreshToken + 1 }));
    } else {
      Notify.error('Erreur dans les changements de status');
    }
  }

  async handleGetSingleByID(product_id) {
    const res = await Ajax.get({
      url: `api/singles_mkm/` + product_id,
      token: true,
    });
    if (res.type === "success") {
      return res.item
    } else {
      return false;
    }
  }

  async handleSellAll(rows) {
    const ids = [];
    rows.forEach((item) => {
      if (
        item.mkm_state_status === "Vérifié" &&
        item.mkm_lock_sync === 0 &&
        item.visibility === 1
      ) {
        ids.push(item.id);
      }
    });
    if (ids.length === 0) {
      Notify.info('Aucun produit à mettre en vente');
      return;
    }
    this.setState({ createOffers: { eventType: 'CONFIRM', ids } });
  }

  handleMassLock(rows) {
    this.setState({ openMassLock: { rows, type: 'lock' } });
  }

  handleMassUnlock(rows) {
    this.setState({ openMassLock: { rows, type: 'unlock' } });
  }

  async sendMassLock(rows, type) {
    await this.asyncSetState({ openMassLock: null });
    const func = type === 'lock' ? 'product_cm_mass_lock' : 'product_cm_mass_unlock';
    const res = await Ajax.patch({
      url: `api/${func}`,
      token: true,
      body: { ids: rows.map(row => row.id) }
    });
    if (res.type === "success") {
      this.setState(prev => ({ refreshToken: prev.refreshToken + 1 }));
      Notify.success(`Produits ${type === 'lock' ? 'bloqués' : 'débloqué' } avec succes`);
    } else {
      console.log(res);
      Notify.error('Une erreur est survenue');
    }
  }

  getRobotCotCotPercent() {
    setTimeout(async () => {
      const { percent } =  await Ajax.get({ url: `api/robotcotcot`, token: true });
      if(this.state.roboCotCotPercent !== percent) {
        this.setState({ roboCotCotPercent: percent });
      }
      if (percent !== -1) {
        this.getRobotCotCotPercent();
      }
    }, 5000);
  }

  async handleRobotCotCot() {
    const { lastRowCount, lastQueryString, roboCotCotPercent } = this.state;
    if (roboCotCotPercent !== -1 || lastRowCount > 10000 || lastRowCount === 0) {
      Notify.error('Impossible de lancer Robot Cot²');
      return;
    }
    const body = { ...lastQueryString };
    await this.asyncSetState({ roboCotCotPercent: 0 });
    delete body.list;
    delete body.count;
    delete body.limit;
    delete body.offset;
    delete body.direction;
    const result = await Ajax.post({ url: `api/robotcotcot`, body, token: true });
    if(result.type !== 'success') {
      Notify.error('Une erreur est survenue');
    }
    this.getRobotCotCotPercent();
  }

  setEventSell(data) {
    const time = Date.now();
    const diff = time - this.eventSellLastTime;
    clearTimeout(this.eventSellLastId);
    if (diff > 1000) {
      this.eventSellLastTime = time;
      this.setState({ createOffers: data });
    } else {
      this.eventSellLastId = setTimeout(() => this.setEventSell(data), 1001 - diff);
    }
  }

  async confirmSell() {
    const { ids, percent } = { ids: [], percent: 0, ...this.state.createOffers };
    await this.asyncSetState({createOffers: { eventType: 'PROGRESS', ids, percent }});
    const result = await Ajax.post({ 
      url: 'api/product_cm/create_offers',
      token: true, 
      body: {ids},
      onEvent: data => this.setEventSell({ eventType: 'PROGRESS', percent: data.percent || 0, ids }),
    });
    if (result.type === 'success') {
      Notify.success('Mise en vente terminée');
    } else {
      Notify.error('Une erreur est survenue');
    }
    this.setState(prev => ({ createOffers: { eventType: 'CLOSE' }, refreshToken: prev.refreshToken + 1 }));
  }

  /** RENDER */
  renderCreateOffer() {
    const { eventType, ids, percent } = { ids: [], percent: 0, ...this.state.createOffers };
    return (
      <Confirm
        open={eventType !== 'CLOSE'}
        title="Mise en vente de produits"
        message={
          eventType === 'CONFIRM' 
            ? `Voulez vous mettre en vente ces ${ids.length} produit(s) ?`
            : `Mise en vente en cours : ${percent}%`
        }
        onValidate={eventType === 'CONFIRM' ? () => this.confirmSell() : null}
        onClickAway={() => this.setState({ createOffers: { eventType: 'CLOSE' }} )}
      />
    );
  }

  renderMassLock() {
    const { openMassLock } = this.state;
    const { rows, type } = openMassLock || {};
    return (
      <Confirm
        open={!!openMassLock}
        title={`${type === 'lock' ? 'Bloquer' : 'Débloquer'} des produits`}
        message={
          `Voulez vous ${type === 'lock' ? 'bloquer' : 'débloquer'} ces ${(rows || []).length} produits ?`
        }
        onValidate={() => this.sendMassLock(rows, type)}
        onClickAway={() => this.setState({ openMassLock: null })}
      />
    );
  }

  renderIdCell({ value }) {
    const url = parkageProductUrl(value);
    return <Button component="a" href={url} variant="text" color="inherit" target="_blank">{value}</Button>;
  }

  renderFinderWebsiteCell({ row }) {
    if(row.website) {
      const url = "https://www.cardmarket.com/" + row.website;
      return <Button component="a" href={url} variant="text" color="inherit" target="_blank">{row.id}</Button>;
    }
    return row.id;
  }

  renderWebsiteCell({ row }) {
    if (row?.mkm_product_id) {
      let url = "https://www.cardmarket.com" + row?.mkm_website;
      if (row.mkm_quoting_status) {
        let lang = languages.get(row.lang);
        if (row.game_id === 1 && (lang===1 || lang === 2)) {
          lang = '1,2';
        }
        url += `?language=${lang}`;
        url += `&isPlayset=N`;
        url += `&isFoil=${row.is_foil ? 'Y' : 'N'}`;
        if (row.mkm_quoting_status === 'Très bon' || row.mkm_quoting_status === 'Mauvais') {
          url += `&sellerType=1,2`;
        }
        if (row.mkm_quoting_status === 'Très bon' || row.mkm_quoting_status === 'Bon' || row.state !== 'new') {
          url += `&sellerCountry=12`;
        }
        if (row.state === 'new') {
          url += `&minCondition=2`;
        }
      }
      return <Button component="a" href={url} variant="text" color="inherit" target="_blank">{row?.mkm_product_id}</Button>;
    }
    return '';
  }

  /** FORMATTERS */
  renderStatus(row) {
    if (!mkmStatusMatch[row.row.mkm_state_status]) {
      return row.row.mkm_state_status;
    }
    const { select, color } = mkmStatusMatch[row.row.mkm_state_status];
    const { classes } = this.props;
    if (select.length > 0) {
      return (
        <FormControl className={classes.formControlStatus} size="small" sx={{ '.MuiInputLabel-root': { color: `${color} !important` } }}>
          <InputLabel id="demo-select-small-label">
            {row.row.mkm_state_status}
            {row.row.mkm_state_status === 'À vérifier' && row.row.mkm_state_status_detail ? ` ${row.row.mkm_state_status_detail}/10` : null}
          </InputLabel>
          <Select id="demo-select-small" value="" onChange={e => this.handleChangeStatus(e.target.value, [row.row])}>
            {select.map(value => <MenuItem value={value} key={value}>{value}</MenuItem>)}
          </Select>
        </FormControl>
      );
    }
    return <Typography variant='body1' color={color}>{row.row.mkm_state_status}</Typography>
  }

  renderMkmPreviewCell(attr) {
    let value = '';
    if (attr.value) {
      const { host, protocol } = window.location;
      value = `${protocol}//${host}/${attr.value}`;
    }
    return renderPreviewCell({ ...attr, value });
  }

  stockRenderCell(params) {
    const style = {};
    const number = parseInt(params.value, 10);
    if (number <= 0) {
      style.color = 'red';
    }
    return <span style={style}>{Number.isNaN(number) ? 'NULL' : number}</span>;
  }

  lockRenderCell(params) {
    return (
      <Tooltip key="Rapport" title={params.value > 0 ? "Synchroniser" : "Stoper la synchronisation"}>
        <span>
          <IconButton color="secondary" onClick={async () => {
            await this.handleMkmLockUnlockSync(params.id, (params.value > 0))
          }}>
            {params.value > 0 ? <LockOutlinedIcon /> : <LockOpenOutlinedIcon />}
          </IconButton>
        </span>
      </Tooltip>
    )
  }

  actionRenderCell({ row, value }) {
    if (row.mkm_state_status === 'Matchs multiple') {
      return (
        <Button onClick={() => {
          try {
            const data = JSON.parse(value);
            this.setState({ multipleMatch: { data, row } });
          } catch {
            Notify.error('Erreur dans la récupération des matchs');
            console.log(value);
          }
        }}>Voir les matchs</Button>
      );
    } else {
      return (
        <Button onClick={() => this.setState({ finder: { row, options: [], search: '', expansionId: 0 } })}>
          Chercher des produits
        </Button>
      );
    }
  }

  nameFormateur({ row }) {
    return (
      <Stack direction="column" title={`${row.name_fr} - ${row.name_en}`}>
        <div style={{ fontSize: 15, lineHeight: 'normal' }}>{row.name_fr}</div >
        <div style={{ fontSize: 12, lineHeight: 'normal' }}>{row.name_en}</div >
      </Stack>
    );
  }

  multiValueFormateur({ value }) {
    const name_fr = (value || []).map(({ name_fr }) => name_fr).join(', ') || '';
    const name_en = (value || []).map(({ name_en }) => name_en).join(', ') || '';
    return (
      <Stack direction="column" title={`${name_fr} - ${name_en}`}>
        <div title={name_fr} style={{ fontSize: 15, lineHeight: 'normal' }}>{name_fr}</div >
        <div title={name_en} style={{ fontSize: 12, lineHeight: 'normal' }}>{name_en}</div >
      </Stack>
    );
  }

  treatmentsFormateur({ value }) {
    const nameList = (value || []).map(({ name }) => name).join(', ') || '';
    return (
      <div style={{ fontSize: 15, lineHeight: 'normal', whiteSpace: 'normal' }} title={nameList}>
        {nameList}
      </div>
    );
  }

  renderMatchRowFromMulti({row}) {
    const { multipleMatch } = this.state;
    const id = multipleMatch?.row?.id
    return (
      <IconButton 
        size="small" 
        title="Valider ce match" 
        onClick={() => {
          this.setState({ multipleMatch: null});
          this.handleChangeProductId(id, row.id)
        }}
      >
        <CheckIcon />
      </IconButton>
    );
  }

  renderMatchRowFromFinder(idPk, idMkm) {
    return (
      <IconButton 
        size="small" 
        title="Valider ce match" 
        onClick={() => {
          this.setState({ finder: { row: null, options: [], search: '', expansionId: 0 }});
          this.handleChangeProductId(idPk, idMkm)
        }}
      >
        <CheckIcon />
      </IconButton>
    );
  }


  /** FILTERS */

  getOptionNomenclature(id) {
    const { nomenclatures } = this.state;
    const nomenclature = nomenclatures.find(item => item.id === id);
    return nomenclature?.name || '';
  }

  getOptionLabelBool(opt) {
    if (opt === '1') {
      return 'Oui';
    } else if (opt === '0') {
      return 'Non';
    }
    return 'Tout';
  }

  getOptionLabelType(opt) {
    if (opt === 'unite') {
      return 'Unité';
    } else if (opt === 'sealed') {
      return 'Scellé';
    }
    return 'Tout';
  }

  /** ACTIONS */
  renderReportButton() {
    const { reportsModal } = this.state;
    return (
      <Tooltip key="Rapport" title="Voir les rapports">
        <span>
          <IconButton color="secondary" onClick={() => this.setState({ reportsModal: !reportsModal })}>
            <AssessmentIcon />
          </IconButton>
        </span>
      </Tooltip>
    )
  }

  renderRobotCotCotButton() {
    const { roboCotCotPercent } = this.state;
    return (
      <Tooltip key="robotcotcot" title="Robot Cot²">
        <Badge color="secondary" badgeContent={roboCotCotPercent === null || roboCotCotPercent === -1 ? null : `${Math.round(roboCotCotPercent * 100)}%`}>
          <IconButton color="secondary" disabled={roboCotCotPercent !== -1} onClick={() => this.setState({ openRobotCotCot: true })}>
            <RobotCotCotIcon />
          </IconButton>
        </Badge>
      </Tooltip>
    );
  }

  /** CONFIRM AND DIALOG */
  renderConfirmUpdateStatus() {
    const { changeStatus } = this.state;
    const status = changeStatus?.status || '';
    const rows = changeStatus?.rows || [];
    return (
      <Confirm 
        open={!!changeStatus}
        title="Changement de status"
        message={`Voulez vous changer le status des ${rows.length} lignes par ${status} ?`}
        onClickAway={() => this.setState({ changeStatus : null })}
        onValidate={() => {
          this.onValidateChangeStatus(status, rows);
          this.setState({ changeStatus: null });
        }}
      />
    );
  }

  renderConfirmCotCot() {
    const { openRobotCotCot, lastRowCount } = this.state;
    return (
      <Confirm 
        open={!!openRobotCotCot}
        title={<><RobotCotCotIcon style={{verticalAlign: 'middle'}} /> Lancer une cotcotation</>}
        message={(
          <>
            <div style={{marginBottom: 8}}>Voulez lancer la cotation sur ces {lastRowCount} produits ?</div>
            <div>Attention seuls les produits vérifiés avec une correspondance CM seront cotés</div>
          </>
        )}
        onClickAway={() => this.setState({ openRobotCotCot : false })}
        onValidate={() => {
          this.setState({ openRobotCotCot: false }, this.handleRobotCotCot);
        }}
      />
    );
  }

  renderFinderDialog() {
    const { row, options, search, expansionId } = this.state.finder;
    const { classes } = this.props;
    let game_id = null;
    if (row?.nomenclature_id) {
      game_id = nomenclatureGameId.get(row?.nomenclature_id) || null;
    }
    const onInputChange = debounce(async evt => {
      const term = evt.target.value;
      this.setState(prev => ({ finder: {...prev.finder, search: term }}));
      const result = await Ajax.get({ 
        url: 'api/singles_mkm',
        token: true,
        queryString: { 
          limit: 50, 
          list: true, 
          game_id, 
          search: term || '', 
          expansion_id: expansionId || undefined,
          type: row.type 
        } 
      });
      this.setState(prev => ({ finder: { ...prev.finder, options: result.list } }));
    }, 300);
    const onChangeExpansion = async value => {
      this.setState(prev => ({ finder: { ...prev.finder, expansionId: value || undefined } }));
      const result = await Ajax.get({
        url: 'api/singles_mkm',
        token: true,
        queryString: {
          limit: 50, 
          list: true, 
          game_id, 
          search: search || '', 
          expansion_id: value || undefined,
          type: row.type,
        }
      });
      this.setState(prev => ({ finder: { ...prev.finder, options: result.list } }));
    }
    const dataOneRow = row ? [row] : [];
    return (
      <Dialog maxWidth="xl" sx={{ height: '100%' }} fullWidth onClose={() => this.setState({ finder: {row: null, options: []} })} open={!!row}>
        <DialogContent sx={{ minHeight: "33rem" }}>
          <div className={classes.wrapper}>
            <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ marginBottom: 1.5 }}>
              <Typography variant="h4" sx={{ fontSize: { xs: '1rem', sm: '1rem', md: '1.6rem' } }}>Trouver des produits</Typography>
              <Stack direction="row" justifyContent="end" alignItems="flex-end" spacing={1}>
                <AutoComplete BoxProps={{ style: { width: 400}}} value={expansionId} variant="outlined" label="Extension" onChange={onChangeExpansion} route="api/expansions_mkm" labelKey="name_fr" searchKey="search" idKey="id" />
                <TextField
                  label="Chercher un produit CM"
                  placeholder='Num carte, nom FR/EN, id CM'
                  size="small" style={{ width: '100%', maxWidth: 400 }}
                  variant="standard"
                  onChange={onInputChange}
                  autoFocus
                />
              </Stack>
            </Stack>
            <MuiDataGrid
              sx={{
                maxHeight: '100%',
                '& .MuiDataGrid-main': {
                  minHeight: '120px'
                },
                '& .MuiDataGrid-footerContainer': {
                  display: 'none',
                }
              }}
              rows={dataOneRow}
              rowCount={dataOneRow.length}
              disableSelectionOnClick
              disableDensitySelector
              disableExtendRowFullWidth
              disableColumnMenu
              disableColumnFilter
              getRowId={row => (row.id)}
              columns={[
                { field: 'id', headerName: 'ID', width: 90, renderCell: this.renderIdCell, sortable: false },
                { field: 'lang', headerName: 'Langue', width: 80, sortable: false },
                { field: 'image_url_1', headerName: 'Aperçu', align: 'center', width: 85, sortable: false, renderCell: renderPreviewCell },
                { field: 'name_fr', headerName: 'Désignation', width: 300, renderCell: renderDesignationCellLite, sortable: false },
                { field: 'sets', headerName: 'Sets', width: 300, renderCell: this.multiValueFormateur, sortable: false },
                { field: 'rarity', headerName: 'Rareté', width: 300, renderCell: this.multiValueFormateur, sortable: false },
                { field: 'card_number', headerName: 'Numéro de carte', width: 150, sortable: false },
              ]}
            />
            <MuiDataGrid
              sx={{
                maxHeight: '100%',
                '& .MuiDataGrid-main': {
                  minHeight: '23rem'
                },
                '& .MuiDataGrid-footerContainer': {
                  display: 'none',
                }
              }}
              rows={options}
              rowCount={options.length}
              disableSelectionOnClick
              disableDensitySelector
              disableExtendRowFullWidth
              disableColumnMenu
              disableColumnFilter
              getRowId={row => (row.id)}
              columns={[
                { field: '__', headerName: '', width: 60, renderCell: ({ row: opt }) => this.renderMatchRowFromFinder(row.id, opt.id), sortable: false },
                { field: 'id', headerName: 'ID', width: 80, sortable: false, renderCell: this.renderFinderWebsiteCell  },
                { field: 'image', headerName: 'Aperçu', width: 90, renderCell: this.renderMkmPreviewCell, sortable: false },
                { field: 'name_fr', headerName: 'Nom', width: 300, renderCell: this.nameFormateur, sortable: false },
                { field: 'expansion_name_fr', headerName: 'Nom de l\'extension', width: 300, sortable: false },
                { field: 'rarity', headerName: 'Rareté', width: 150, sortable: false },
                { field: 'card_number', headerName: 'Numéro de carte', width: 150, sortable: false },
              ]}
              ref={this.datagridRef}
            />
          </div>
        </DialogContent>
      </Dialog>
    );
  }

  renderMultipleMatchDialog() {
    const { multipleMatch, productIdMap } = this.state;
    const { classes } = this.props;
    const json = multipleMatch?.data || [];
    const data = json.map(row => ({ ...row.single, expansionRank: row.expansionRank, nameRank: row.nameRank, rank: row.rank }));
    const id = multipleMatch?.row?.id || 0;
    const dataOneRow = multipleMatch?.row ? [multipleMatch?.row] : [];
    return (
      <Dialog maxWidth="xl" sx={{ height: '100%' }} fullWidth onClose={() => this.setState({ multipleMatch: null })} open={!!multipleMatch}>
        <DialogContent sx={{ minHeight: "33rem" }}>
          <div className={classes.wrapper}>
            <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{marginBottom: 1.5}}>
              <Typography variant="h4" sx={{ fontSize: { xs: '1rem', sm: '1rem', md: '1.6rem' } }}>Liste des matchs</Typography>
              <TextField
                label="ID de produit pour match manuel"
                size="small" style={{ width: '100%', maxWidth: 400 }}
                variant="standard"
                value={productIdMap[id] || ''}
                onChange={evt => this.setState(prev => ({ productIdMap: { ...prev.productIdMap, [id]: evt.target.value } }))}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton size="small" onClick={() => {
                        this.setState(prev => ({ productIdMap: { ...prev.productIdMap, [id]: '' } }))
                        this.handleChangeProductId(id, productIdMap[id] || '');
                      }}>
                        <SaveIcon />
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
            </Stack>
            <MuiDataGrid
              sx={{
                maxHeight: '100%',
                '& .MuiDataGrid-main': {
                  minHeight: '120px'
                },
                '& .MuiDataGrid-footerContainer':{
                  display: 'none',
                }
              }}
              rows={dataOneRow}
              rowCount={dataOneRow.length}
              disableSelectionOnClick
              disableDensitySelector
              disableExtendRowFullWidth
              disableColumnMenu
              disableColumnFilter
              getRowId={row => (row.id)}
              columns={[
                { field: 'id', headerName: 'ID', width: 90, renderCell: this.renderIdCell, sortable: false },
                { field: 'lang', headerName: 'Langue', width: 80, sortable: false },
                { field: 'image_url_1', headerName: 'Aperçu', align: 'center', width: 85, sortable: false, renderCell: renderPreviewCell },
                { field: 'name_fr', headerName: 'Désignation', width: 300, renderCell: renderDesignationCellLite, sortable: false },
                { field: 'sets', headerName: 'Sets', width: 300, renderCell: this.multiValueFormateur, sortable: false },
                { field: 'rarity', headerName: 'Rareté', width: 300, renderCell: this.multiValueFormateur, sortable: false },
                { field: 'card_number', headerName: 'Numéro de carte', width: 150, sortable: false },
              ]}
            />
            <MuiDataGrid 
              sx={{ 
                maxHeight: '100%',
                '& .MuiDataGrid-main': {
                  minHeight: '23rem'
                },
                '& .MuiDataGrid-footerContainer': {
                  display: 'none',
                }
              }}
              rows={data}
              rowCount={data.length}
              disableSelectionOnClick
              disableDensitySelector
              disableExtendRowFullWidth
              disableColumnMenu
              disableColumnFilter
              getRowId={row => (row.id)}
              columns={[
                { field: '__', headerName: '', width: 60, renderCell: this.renderMatchRowFromMulti, sortable: false },
                { field: 'id', headerName: 'ID', width: 80, sortable: false, valueFormatter: ({ value }) => value >= 1 ? value : '', renderCell: this.renderFinderWebsiteCell },
                { field: 'image', headerName: 'Aperçu', width: 90, renderCell: this.renderMkmPreviewCell, sortable: false },
                { field: 'name_fr', headerName: 'Nom', width: 300, renderCell: this.nameFormateur, sortable: false },
                { field: 'expansion_name_fr', headerName: 'Nom de l\'extension', width: 300, sortable: false },
                { field: 'rarity', headerName: 'Rareté', width: 150, sortable: false },
                { field: 'card_number', headerName: 'Numéro de carte', width: 150, sortable: false },
                { field: 'rank', headerName: 'Qualité du match', width: 150, valueFormatter: ({ value }) => `${value}/10`, sortable: false },
              ]}
              ref={this.datagridRef}
            />
          </div>
        </DialogContent>
      </Dialog>
    );
  }

  /** REPORTS */

  renderReports() {
    const { classes } = this.props;
    const { reportsModal, refreshToken } = this.state

    return (
      <Dialog maxWidth="xl" sx={{ height: '100%' }} fullWidth onClose={() => this.setState({ reportsModal: !reportsModal })} open={reportsModal}>
        <DialogContent sx={{ minHeight: "33rem" }}>
          <div className={classes.wrapper}>
            <EditableList
              refreshToken={refreshToken}
              title="Rapports de synchronisation"
              route="reports"
              rowKey="id"
              maxSize="sm"
              defaultParams={{
                order: 'report_creation_date',
                direction: 'DESC',
              }}
              disabledHashedState
              queryStringEnrich={() => ({ reportType: ["CardMarket Auto Find", "Card Market Auto Update", "CardMarket Selling"] })}
              columns={[
                { field: 'id', headerName: 'ID', width: 60 },
                {
                  field: '__actions_export', headerName: 'Export', width: 90, sortable: false, renderCell: ({ row }) => (
                    <Tooltip title="Exporter la data">
                      <span>
                        <IconButton onClick={() => this.handleReportExports(row)}>
                          <SaveAltIcon />
                        </IconButton>
                      </span>
                    </Tooltip>
                  )
                },
                { field: 'report_success_total', headerName: 'Réussite', width: 120, sortable: false },
                { field: 'report_failed_total', headerName: 'Echec', width: 80, sortable: false },
                { field: 'report_pending_total', headerName: 'En attente', width: 120, sortable: false },
                { field: 'report_creation_date', headerName: 'Date de création', width: 200, valueFormatter: dateTimeEnToDateTimeFr },
                { field: 'report_type', headerName: 'Type', width: 200 },
              ]}
              filterParams={[
                { key: 'id', label: 'ID' },
                { key: 'reportCreationDateMin', label: 'Date de création min', type: 'date' },
                { key: 'reportCreationDateMax', label: 'Date de création max', type: 'date' },
                { key: 'reportType', label: 'Type de rapport', type: 'select', options: ['', "CardMarket Auto Find", "Card Market Auto Update", "CardMarket Selling"], getOptionLabel: opt => opt || 'Tout', variant: 'standard' },
              ]}
              searchPlaceholder="ID"
              allowEdit={false}
              allowAdd={false}
              allowDelete={false}
              allowExport={false}
            />
          </div>
        </DialogContent>
      </Dialog>
    )
  }

  /** RENDER TABLE */
  render() {
    const { classes } = this.props;
    const { refreshToken, nomenclatures } = this.state
    return (
      <div className={classes.wrapper}>
       
        <EditableList
          title="Card Market - Produits Parkage"
          route="products"
          searchKey="search"
          refreshToken={refreshToken}
          queryStringEnrich={() => ({ useMatchData: true, useTreatments: true })}
          columns={[
            { field: 'id', headerName: 'ID', width: 90, renderCell: this.renderIdCell },
            { field: 'state', headerName: 'Etat', width: 90 },
            { field: 'lang', headerName: 'Langue', width: 60 },
            { field: 'image_url_1', headerName: 'Aperçu', align: 'center', width: 85, sortable: false, renderCell: renderPreviewCell },
            { field: 'name_fr', headerName: 'Nom PK', width: 300, renderCell: renderDesignationCellLite },
            { field: 'sets', headerName: 'Sets', width: 300, renderCell: this.multiValueFormateur, sortable: false },
            { field: 'rarity', headerName: 'Rareté PK', width: 120, renderCell: this.multiValueFormateur, sortable: false },
            { field: 'treatments', headerName: 'Traitements', width: 120, renderCell: this.treatmentsFormateur, sortable: false },
            { field: 'card_number', headerName: 'N° carte PK', width: 120 },
            { field: 'mkm_name_fr', headerName: 'Nom CM', width: 300, renderCell: (item) =>  this.nameFormateur({row: {name_fr: item.row.mkm_name_fr, name_en: item.row.mkm_name_en}})},
            { field: 'mkm_rarity', headerName: 'Rareté CM', width: 120},
            { field: 'mkm_card_number', headerName: 'N° carte CM', width: 120, sortable: false },
            { field: 'mkm_article_id', headerName: 'Id article CM', width: 120, valueFormatter: ({ value }) => value >= 1 ? value : '' },
            { field: 'mkm_product_id', headerName: 'Id produit CM', width: 120, valueFormatter: ({ value }) => value >= 1 ? value : '', renderCell: this.renderWebsiteCell },
            { field: 'mkm_image_url', headerName: 'Aperçu CM', width: 85, sortable: false, renderCell: this.renderMkmPreviewCell },            
            { field: 'mkm_state_status_detail', headerName: 'Action', width: 200, sortable: false, renderCell: this.actionRenderCell, },
            { field: 'mkm_state_status', headerName: 'Status', width: 200, renderCell: this.renderStatus },
            { field: 'mkm_lock_sync', headerName: 'Lock CM', width: 80, renderCell: this.lockRenderCell },
            { field: 'mkm_sync', headerName: 'Sync CM', width: 80, valueFormatter: ({ value }) => value >= 1 ? 'Oui' : 'Non' },
            { field: 'stock', headerName: 'Stock', align: 'center', width: 100, renderCell: this.stockRenderCell },
            { field: 'visibility', headerName: 'Visible', width: 90, valueFormatter: ({ value }) => value >= 1 ? 'Oui' : 'Non' },
            { field: 'nomenclature_name', headerName: 'Nomenclature', width: 150, },
            { field: 'price', headerName: 'Prix de vente', width: 120 },
            { field: 'price_buy', headerName: 'Prix de d\'achat', width: 120 },
            { field: 'mkm_quoting_price', headerName: 'Cotation', width: 120 },
            { field: 'mkm_quoting_status', headerName: 'Qualité de la cotation', width: 120 },
            { field: 'mkm_quoting_date', headerName: 'Date de la cotation', width: 120 },
          ]}
          filterParams={[
            { key: 'id', label: 'ID' },
            { key: 'lang', label: 'Langue', type: 'select', options: [...langTrad.keys()], getOptionLabel: key => langTrad.get(key) },
            { key: 'visibility', label: 'Visible', type: 'select', options: ['', '0', '1'], getOptionLabel: this.getOptionLabelBool, variant: 'standard' },
            { label: 'Stock', startkey: 'stockStart', endkey: 'stockEnd', type: 'range' },
            { key: 'mkm_product_id', label: 'Id Produit Cardmarket', variant: 'standard' },
            { key: 'hasMkmProductId', label: 'Id Produit existant', type: 'select', options: ['', '0', '1'], getOptionLabel: this.getOptionLabelBool, variant: 'standard' },
            { key: 'mkm_sync', label: 'Synchronisation Cardmarket', type: 'select', options: ['', '0', '1'], getOptionLabel: this.getOptionLabelBool, variant: 'standard' },
            { key: 'mkm_lock_sync', label: 'Cardmarket Lock', type: 'select', options: ['', '0', '1'], getOptionLabel: this.getOptionLabelBool, variant: 'standard' },
            { key: 'mkm_state_status', label: 'Cardmarket status', type: 'select', options: ['Vérifié', 'À vérifier', 'Non matché', 'Matchs multiple', 'En attente'], variant: 'standard' },
            { key: 'type', label: 'Type', type: 'select', options: ['', 'unite', 'sealed'], variant: 'standard', getOptionLabel: this.getOptionLabelType },
            { key: 'nomenclature_id', label: 'Nomenclature', type: 'select', multiple: true, options: nomenclatures.map(nomenclature => nomenclature.id), getOptionLabel: this.getOptionNomenclature },
            {
              key: 'taxonomy_value_id', label: 'Extension', type: 'autocomplete', 
              route: 'api/taxonomy_value/expansion', searchKey: 'search', idKey: 'id', labelKey: 'name_fr', 
              getOptionLabel: item => !item ? '' : `${item.abreviation ? `[${item.abreviation}]` : ''} ${item.name_fr}` 
            },
            { label: 'Prix de vente', startkey: 'sell_price_min', endkey: 'sell_price_max', type: 'range' },
            { label: 'Prix d\'achat', startkey: 'buy_price_min', endkey: 'buy_price_max', type: 'range' },
            { label: 'Cotation', startkey: 'mkm_quoting_min', endkey: 'mkm_quoting_max', type: 'range' },
            { key: 'mkm_quoting_status', label: 'Etat de la cotation', type: 'select', multiple: true, options : ['Très bon', 'Bon', 'Mauvais', 'Très mauvais', 'Indéterminé', 'Non coté'] },
            { key: 'mkm_quoting_date_min', label: 'Date de cotation min', type: 'date' },
            { key: 'mkm_quoting_date_max', label: 'Date de cotation max', type: 'date' },
          ]}
          searchPlaceholder="Filtrer par nom, référence, code barre"
          actions={[
            this.renderRobotCotCotButton(),
            this.renderReportButton(),
          ]}
          rowActions={[
            { key: "handleVerified", label: "Vérifier ces produits", onClick: rows => this.handleChangeStatus('Vérifié', rows) },
            { key: "handleNomatch", label: "Enlever la vérification de ces produits", onClick: rows => this.handleChangeStatus('Non matché', rows) },
            { key: "handleSellAll", label: "Mettre les produits vérifiés en vente", onClick: this.handleSellAll },
            { key: "handleMassLock", label: "Bloquer les produits", onClick: this.handleMassLock },
            { key: "handleMassUnlock", label: "Débloquer les produits", onClick: this.handleMassUnlock },
          ]}
          maxSize="xl"
          allowExport={false}
          allowEdit={false}
          allowAdd={false}
          allowDelete={false}
          beforeFetchPage={({ queryString }) => this.setState({ lastQueryString: queryString })}
          afterFetchPage={({ rowCount }) => this.setState({ lastRowCount: rowCount })}
        />
        {this.renderReports()}
        {this.renderConfirmUpdateStatus()}
        {this.renderConfirmCotCot()}
        {this.renderMultipleMatchDialog()}
        {this.renderFinderDialog()}
        {this.renderCreateOffer()}
        {this.renderMassLock()}
      </div>
    );
  }
}

Products.propTypes = {
  classes: PropTypes.object.isRequired,
}
export default withStyles(styles)(Products);
