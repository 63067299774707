import React from 'react';
import { withStyles } from '@mui/styles';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '@mui/material';
import PropTypes from 'prop-types';
import EditableList from '../../containers/EditableList/index.js';
import { dateEnToDateFr, booleanFormatter } from '../../utils/ColumnFormatter';
import Ajax from '../../utils/Ajax';
import styles from './styles.js';
import Notify from '../../components/base/Notify/index.js';

class ExpansionMKM extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      refreshToken: 0,
      games_id: [],
      games_name: {},
      downloadExp: { eventType: 'CLOSE' },
      matchinProgress: { eventType: 'CLOSE' },
      matchingRows: null,
    };
    this.dowloadlastTime = 0;
    this.dowloadlastId = 0;
    this.matchinglastTime = 0;
    this.matchinglastId = 0;
    this.booleanLabel = {'': 'Tous', '0': 'Non', '1': 'Oui'};
    this.handleBulkLoadEx = this.handleBulkLoadEx.bind(this);
    this.handleBulkMatchingEx = this.handleBulkMatchingEx.bind(this);
    this.dialogMatching = this.dialogMatching.bind(this);
    this.handleValidateMatch = this.handleValidateMatch.bind(this);
  }
  
  asyncSetState(params) {
    return new Promise(resolve => {
      this.setState(params, resolve);
    });
  }

  async componentDidMount() {
    const result = await Ajax.get({ url: 'api/expansions_mkm/options', token: true});

    const games_name = {};
    const games_id = [''];
    result.games.forEach(game => {
      games_id.push(game.mkm_game_id);
      games_name[game.mkm_game_id] = game.name_fr;
    })
    this.setState({ games_name, games_id });
  }

  handleBulkLoadEx(rows) {
    this.setState({ downloadExp: { eventType: 'CONFIRM', rows: rows } });
  }

  handleBulkMatchingEx(rows) {
    for(let i=0; i< rows.length; i++) {
      if(!rows[i].loaded) {
        Notify.warning("Les extensions doivent être chargées");
        return;
      }
    }
    this.setState({ matchingRows: rows })
  }

  setEventDownload(data) {
    const time = Date.now();
    const diff = time - this.dowloadlastTime;
    clearTimeout(this.dowloadlastId);
    if (diff > 1000) {
      this.dowloadlastTime = time;
      this.setState({ downloadExp: data });
    } else {
      this.dowloadlastId = setTimeout(() => this.setEventDownload(data), 1001 - diff);
    }
  }

  setEventMatching(data) {
    const time = Date.now();
    const diff = time - this.matchinglastTime;
    clearTimeout(this.matchinglastId);
    if (this.state?.matchinProgress?.eventType !== 'CLOSE') {
      if (diff > 1000) {
        this.matchinglastTime = time;
        this.setState({ matchinProgress: data });
      } else {
        this.matchinglastId = setTimeout(() => this.setEventMatching(data), 1001 - diff);
      }
    }
  }

  async handleConfirmLoadEx() {
    let { rows } = this.state.downloadExp;
    await this.asyncSetState({ downloadExp: { eventType: 'PROGRESS', rows, currentId: 0, percent: 0 } });

    const result = await Ajax.put({
      url: 'api/singles_mkm/load_expansions',
      token: true,
      body: { ids: this.state.downloadExp.rows.map(row => row.id) },
      onEvent: data => {
        this.setEventDownload({
          eventType: data.eventType || 'PROGRESS',
          rows: rows,
          currentId: data.currentId || 0,
          percent: data.percent || 0,
        });
      },
    });

    if (result.type === 'success') {
      if (result.eventType === 'ALREADY') {
        Notify.warning('Un autre téléchargement est en cours !');
      } else {
        Notify.success('Téléchargement terminé');
      }
    } else {
      Notify.error('Une erreur est survenue');
    }
    this.setState(prev => ({ downloadExp: { eventType: 'CLOSE' }, refreshToken: prev.refreshToken + 1 }));
  }

  async handleConfirmStartMatching(mkm_expasion_id, pk_expansion_id) {
    // const { name_fr, game_name_fr } = this.state.matchingRow;
    await this.asyncSetState({ matchingRows: null, matchinProgress: { eventType: 'PROGRESS', percent: 0 } });
    const result = await Ajax.put({
      url: 'api/product_cm_auto_find',
      token: true,
      body: { mkm_expasion_id, pk_expansion_id },
      onEvent: data => {
        this.setEventMatching({
          eventType: data.eventType || 'PROGRESS',
          step: data.step || 'CHARGEMENT',
          percent: data.percent || 0,
        });
      }
    });
    if (result.type === 'success') {
      Notify.success('Matching terminé');
    } else {
      Notify.error('Une erreur est survenue');
    }
    this.setState(prev => ({ matchinProgress: { eventType: 'CLOSE' }, refreshToken: prev.refreshToken + 1 }));
  }

  nameFormateur({ row }) {
    return (
      <>
        <div style={{ fontSize: 15, lineHeight: 'normal' }}>{row.name_fr}</div >
        <div style={{ fontSize: 12, lineHeight: 'normal' }}>{row.name_en}</div >
      </>
    );
  }

  handleValidateMatch(rows) {
    const { matchingRows } = this.state;
    const mkm_expasion_ids = (matchingRows || []).map(match => match.id);
    const pk_expansion_ids = rows.map(row => row.id);
    this.handleConfirmStartMatching(mkm_expasion_ids, pk_expansion_ids);
  }

  dialogMatching() {
    const { matchingRows } = this.state;
    const { classes } = this.props;
    return (
      <Dialog maxWidth="xl" sx={{ height: '100%' }} fullWidth onClose={() => this.setState({ matchingRows: null })} open={!!matchingRows}>
        <DialogContent sx={{ minHeight: "33rem" }}>
          <div className={classes.wrapper}>
            <EditableList
              searchKey="name_fr"
              queryStringEnrich={() => ({ taxonomy_id: 19 })}
              disabledHashedState
              title="Matching d'extensions"
              route="taxonomy_value"
              rowKey="id"
              maxSize="xl"
              columns={[
                { field: 'id', headerName: 'ID', width: 80 },
                { field: 'name_fr', headerName: 'Name Fr', width: 300 },
                { field: 'name_en', headerName: 'Name En', width: 300 },
              ]}
              filterParams={[
                { key: 'name_en', label: 'Nom extension EN' },
              ]}
              rowActions={[
                { key: "handleMatchingEx", label: "Matcher les extensions", onClick: this.handleValidateMatch },
              ]}
              searchPlaceholder="Filtrer par Nom FR"
              allowEdit={false}
              allowAdd={false}
              allowDelete={false}
              allowExport={false}
            />
          </div>
        </DialogContent>
      </Dialog>
    );
  }

  renderDownLoadExp() {
    const { downloadExp } = this.state;
    const { eventType, rows, currentId, percent } = { rows: [], currentId: 0, percent:0, ...downloadExp };
    const exName = (rows.find(ex => ex.id === currentId) || {}).name_fr || currentId;
    return (
      <Dialog open={eventType !== 'CLOSE' && eventType !== 'SUCCESS'} onClose={() => this.setState({ downloadExp: { eventType: 'CLOSE' } })}>
        <DialogTitle>{eventType === 'CONFIRM' ? "Confirmation" : "Téléchargement en cours"}</DialogTitle>
        <DialogContent>
          {eventType === 'CONFIRM' && <DialogContentText>Confirmer le téléchargement des {rows.length} extensions</DialogContentText> }
          {eventType !== 'CONFIRM' && currentId === 0 && <DialogContentText>Chargement en cours...</DialogContentText>}
          {eventType !== 'CONFIRM' && currentId !== 0 && <DialogContentText>Téléchargement de l'extension {exName} {(percent * 100).toFixed(2)}%</DialogContentText>}
        </DialogContent>
        <DialogActions>
          <Button disabled={eventType !== 'CONFIRM'} onClick={() => this.setState({ downloadExp: { eventType: 'CLOSE' } })}>Annuler</Button>
          <Button disabled={eventType !== 'CONFIRM'} type="submit" onClick={() => this.handleConfirmLoadEx()}>Confirmer</Button>
        </DialogActions>
      </Dialog>
    );
  }

  renderMatchingProgress() {
    const { matchinProgress } = this.state;
    const { eventType, step, percent } = { eventType: 'CLOSE', step: 'CHARGEMENT', percent: 0, ...matchinProgress };
    return (
      <Dialog open={eventType !== 'CLOSE'} onClose={() => this.setState({ matchinProgress: { eventType: 'CLOSE' } })}>
        <DialogTitle>Matching en cours</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Etape : {step} - progression : {(percent * 100).toFixed(2)}%
          </DialogContentText>
        </DialogContent>
      </Dialog>
    );
  }

  render() {
    const { classes } = this.props;
    const { refreshToken, games_id, games_name } = this.state;
    return (
      <div className={classes.wrapper}>
        <EditableList
          refreshToken={refreshToken}
          title="Card Market - Extensions des cartes à l'unité"
          route="expansions_mkm"
          rowKey="id"
          maxSize="xl"
          columns={[
            { field: 'id', headerName: 'ID', width: 80 },
            { field: 'loaded', headerName: 'Chargée PK', width: 80, valueFormatter: booleanFormatter },
            { field: 'game_id', headerName: 'Numéro de jeu', width: 80 },
            { field: 'game_name_fr', headerName: 'Nom du jeu', width: 300, sortable: false },
            { field: 'name_fr', headerName: 'Nom de l\'extension', width: 300, renderCell: this.nameFormateur },
            { field: 'abbreviation', headerName: 'Abréviation', width: 140 },
            { field: 'released', headerName: 'Sortie MKM', width: 80, valueFormatter: booleanFormatter },
            { field: 'release_date', headerName: 'Date de sortie', width: 200, valueFormatter: dateEnToDateFr },
          ]}
          filterParams={[
            { key: 'id', label: 'ID' },
            { key: 'game_id', label: 'Jeux', type: 'select', options: games_id, getOptionLabel: id => id ? games_name[id] : 'Tous' },
            { key: 'name_fr', label: 'Nom extension FR' },
            { key: 'loaded', label: 'Chargée PK', type: 'select', options: ['', '0', '1'], getOptionLabel: key => this.booleanLabel[key] },
            { key: 'released', label: 'Sortie MKM', type: 'select', options: ['', '0', '1'], getOptionLabel: key => this.booleanLabel[key] },
          ]}
          rowActions={[
            { key: "handleLoadEx", label: "Télécharger les extensions", onClick: this.handleBulkLoadEx },
            { key: "handleMatchingEx", label: "Matcher les extensions", onClick: this.handleBulkMatchingEx }
          ]}
          searchPlaceholder="Filtrer par Nom FR/EN"
          allowEdit={false}
          allowAdd={false}
          allowDelete={false}
          allowExport={false}
        />
        {this.renderDownLoadExp()}
        {this.renderMatchingProgress()}
        {this.dialogMatching()}
      </div>
    );
  }
}

ExpansionMKM.propTypes = {
  classes: PropTypes.object.isRequired,
  redirect: PropTypes.func.isRequired,
}

export default withStyles(styles)(ExpansionMKM);
