import React from 'react';
import PropTypes from 'prop-types';
import debounce from 'lodash/debounce';
import AutoSelect from '../AutoSelect';
import Ajax from '../../../utils/Ajax';


class AutoComplete extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      options: [],
    }
    this.onChange = this.onChange.bind(this);
    this.getOptionLabel = this.getOptionLabel.bind(this);
    this.onInputChange = debounce(this.onInputChange.bind(this), 300).bind(this);
  }

  async componentDidMount() {
    const { route } = this.props;
    const result = await Ajax.get({ url: route, token: true, queryString: { limit: 10, list: true } });
    this.setState({ options: result.list });
  }

  onChange(value) {
    const { onChange } = this.props;
    if (!value) {
      this.setState({ list: [] });
    }
    onChange(value);
  }

  getOptionLabel(id) {
    const { idKey, labelKey, getOptionLabel } = this.props;
    const { options } = this.state;
    if (getOptionLabel) {
      return getOptionLabel(id, [...options]);
    }
    if (id) {
      const findItem = options.find(item => item[idKey] === id);
      if (findItem && findItem[labelKey]) {
        return findItem[labelKey];
      }
    }
    return '';
  }

  async onInputChange(evt) {
    const { route, searchKey } = this.props;
    const term = evt.target.value;
    const result = await Ajax.get({ url: route, token: true, queryString: { limit: 10, list: true, [searchKey]: term } });
    this.setState({ options: result.list });
  }

  render() {
    const { idKey, value, name, label, ...other } = this.props;
    const { options } = this.state;
    const list = options.map(item => item[idKey]);
    return (
      <AutoSelect
        name={name}
        label={label}
        variant="outlined"
        value={value || ''}
        filterOptions={id => id}
        onChange={this.onChange}
        options={list}
        onInputChange={this.onInputChange}
        getOptionLabel={this.getOptionLabel}
        {...other}
      />
    );
  }

}
AutoComplete.defaultProps = {
  idKey: 'id',
  searchKey: 'search',
  labelKey: 'label',
  name: '',
}

AutoComplete.propTypes = {
  onChange: PropTypes.func.isRequired,
  route: PropTypes.string.isRequired,
  idKey: PropTypes.string,
  searchKey: PropTypes.string,
  labelKey: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
}

export default AutoComplete;