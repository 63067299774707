import React from 'react';
import PropTypes from 'prop-types';
import { TablePagination, ListItem, ListItemButton, ListItemText, Box, Button, Menu } from '@mui/material';
import theme from '../../../../theme';
import { FixedSizeList } from 'react-window';

class Pagination extends React.Component {
 
  constructor(props) {
    super(props);
    this.state = {
      menuOpen: false,
    }
    this.renderRow = this.renderRow.bind(this);
    this.onOpenMenu = this.onOpenMenu.bind(this);
    this.buttonRef = React.createRef();
    this.listeRef = React.createRef();
  }

  onOpenMenu() {
    if (this.listeRef?.current) {
      const { limit, offset } = this.props;
      const page = Math.floor(offset / limit);
      this.listeRef?.current.scrollToItem(page);
    }
    this.setState({ menuOpen: true });
  }

  renderRow(itemProps) {
    const { index, style } = itemProps;
    const { onPageChange, limit, offset } = this.props;
    const page = Math.floor(offset / limit);
    const sx = {};
    if(index === page) {
      sx.background = theme.palette.primary.main;
      sx.color = '#FFFFFF';
    }
    return (
      <ListItem style={style} key={index} component="div" disablePadding sx={sx}>
        <ListItemButton onClick={() => {
          this.setState({menuOpen: false});
          onPageChange(index);
        }}>
          <ListItemText primary={`${index + 1}`} />
        </ListItemButton>
      </ListItem>
    );
  }

  render() {
    const { limit, offset, count, onPageChange, onPageSizeChange } = this.props;
    const { menuOpen } = this.state;
    const page = Math.floor(offset / limit);
    const nbPage = Math.ceil(count / limit) || 1;
    return (
      <Box sx={{height: 55, display: 'flex', alignItems: 'center'}}>
        <TablePagination 
          sx={{height: 55, padding: 0, margin: 0, border: 0}}
          rowsPerPage={limit} 
          count={count} 
          page={page} 
          onPageChange={(evt, value) => onPageChange(value)}
          onRowsPerPageChange={evt => onPageSizeChange(evt.target.value)}
          component="div"
          labelDisplayedRows={() => `${page + 1}/${nbPage}`}
        />
        <Button sx={{ marginRight: 1 }} ref={this.buttonRef} onClick={this.onOpenMenu}>
          Page : {page + 1}
        </Button>
        <Menu 
          anchorEl={this.buttonRef?.current}
          open={menuOpen}
          onClose={() => this.setState({menuOpen: false})}
          keepMounted
        >
          <FixedSizeList
            ref={this.listeRef}
            height={250}
            width={100}
            itemSize={46}
            itemCount={nbPage}
            overscanCount={5}
          >
            {this.renderRow}
          </FixedSizeList>
        </Menu>
      </Box>
    );
  }
}

Pagination.propTypes = {
  limit: PropTypes.number, 
  offset: PropTypes.number, 
  count: PropTypes.number, 
  onPageChange: PropTypes.func, 
  onPageSizeChange: PropTypes.func,
}


export default Pagination;