import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import isNil from 'lodash/isNil';
import uniqueId from 'lodash/uniqueId';
import debounce from 'lodash/debounce';
import isEqual from 'lodash/isEqual';
import forEach from 'lodash/forEach';
import moment from 'moment';

import IconButton from '@mui/material/IconButton';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import RestoreIcon from '@mui/icons-material/Restore';
import CloseIcon from '@mui/icons-material/Close';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import { DataGrid as MuiDataGrid } from '@mui/x-data-grid';

import DataGrid from '../../components/Grids/DataGrid';
import DragScroll from '../../components/Grids/DataGrid/utils/dragscroll';
import DataGridToolbar from '../../components/Grids/DataGridToolbar';
import Notify from '../../components/base/Notify';
import { dateTimeEnToDateTimeFr } from '../../utils/ColumnFormatter';

import { DEFAULT_PARAMS } from '../../components/Grids/DataGrid/constants';
import { EDITMODAL_MODES } from './constants';

import Ajax from '../../utils/Ajax';
import Download from '../../utils/Download';
import { array_move } from '../../utils/Misc';

import styles from './styles.js';

class EditableList extends React.Component {
  static defaultProps = {
    filterParams: [],
    actions: [],
    rowActions: [],
    filterActions: [],
    rowKey: 'id',
    filterId: 'id',
    searchKey: 'search',
    allowAdd: true,
    allowEdit: true,
    allowDelete: true,
    allowExport: true,
    allowVersion: false,
    disabledHashedState: false,
    requiredFields: [],
    defaultParams: {},
    routeBase: '',
    maxSize: 'md',
  }

  constructor(props) {
    super(props);
    this.state = {
      data: [],
      columnsVisibility: props.columnsVisibility || {},
      columnsOrder: this.initColumnsOrder(props),
      rowCount: 0,
      page: 0,
      filters: {
        [props.searchKey]: '',
        ...DEFAULT_PARAMS,
        order: props.rowKey,
        ...props.defaultParams,
      },
      selectedRows: [],
      editPanelOpen: false,
      editItem: null,
      fetching: false,
      working: false, // Ajax request (add, edit...)
      version: null,
      ...this.getHashedState()
    };

    this.getColumns = this.getColumns.bind(this);
    this.toggleEditModal = this.toggleEditModal.bind(this);

    this.handleAction = this.handleAction.bind(this);
    this.handleSetSelectedRows = this.handleSetSelectedRows.bind(this);
    this.handleChangeFilters = this.handleChangeFilters.bind(this);
    this.handleClearFilters = this.handleClearFilters.bind(this);
    this.handleValidateEdition = this.handleValidateEdition.bind(this);

    this.fetchPage = debounce(this.fetchPage, 600).bind(this);
    this.applyEditItem = this.applyEditItem.bind(this);
    this.applyAddItem = this.applyAddItem.bind(this);
    this.applyDeleteSelection = this.applyDeleteSelection.bind(this);
    this.applyExport = this.applyExport.bind(this);
    this.handleVisibilityChange = this.handleVisibilityChange.bind(this);
    this.handleColumnsOrderChange = this.handleColumnsOrderChange.bind(this);
    this.handleSaveConfiguration = this.handleSaveConfiguration.bind(this);
    this.handleLoadConfiguration = this.handleLoadConfiguration.bind(this);
    this.handleDeleteConfiguration = this.handleDeleteConfiguration.bind(this);
    this.getConfigurations = this.getConfigurations.bind(this);
    this.setHashedState = this.setHashedState.bind(this);
    this.renderVersions = this.renderVersions.bind(this);
    this.closeVersions = this.closeVersions.bind(this);

    this.lastQueryString = {};

    this.versionGridRef = React.createRef();
  }

  getHashedState() {
    if (!this.props.disabledHashedState) {
      const urlParams = new URLSearchParams(window.location.search);
      const id = urlParams.get('hashedState');
      if (id) {
        try {
          const hash = window.sessionStorage.getItem(id);
          const state = JSON.parse(atob(hash));
          if (state) {
            this.hashedStateId = id;
          }
          return state;
        } catch (e) {
          return null;
        }
      }
    }
    return null;
  }

  setHashedState() {
    if (!this.props.disabledHashedState) {
      const { filters, page, columnsVisibility, columnsOrder } = this.state;
      const hash = btoa(JSON.stringify({ filters, page, columnsVisibility, columnsOrder }));
      const id = this.hashedStateId || btoa(uniqueId('hash_'));
      window.sessionStorage.setItem(id, hash);
      if (!this.hashedStateId) {
        var url = new URL(window.location.href);
        url.searchParams.set('hashedState', id);
        this.hashedStateId = id;
        window.history.replaceState(window.history.state, null, url);
      }
    }
  }

  componentDidMount() {
    const { page } = this.state;
    this.fetchPage(page);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.refreshToken !== this.props.refreshToken) {
      this.fetchPage(this.state.page);
    }
  }

  componentWillUnmount() {
    if (this.dragscroll) {
      this.dragscroll.willUnmount();
    }
  }

  /**
   * GET
   */
  async fetchPage(nextPage = null) {
    const { route, routeBase, queryStringEnrich, filterParams, beforeFetchPage, afterFetchPage } = this.props;
    this.setState(prevState => ({ fetching: true, page: isNil(nextPage) ? prevState.page : nextPage }), async () => {
      this.setHashedState();
      let queryStringEnrichValue = {};
      if (queryStringEnrich) {
        queryStringEnrichValue = queryStringEnrich(this.props, this.state);
      }
      const { filters, page } = this.state;
      const filtersFormatted = { ...filters };
      forEach(filterParams, filter => {
        if (!isNil(filtersFormatted[filter.key]) && filter.valueFormatter) {
          filtersFormatted[filter.key] = filter.valueFormatter(filtersFormatted[filter.key]);
        }
      });
      const offset = page * filters.limit;
      if (beforeFetchPage) {
        beforeFetchPage({
          url: `${routeBase}api/${route}`,
          token: true,
          queryString: {
            list: true,
            count: true,
            ...queryStringEnrichValue,
            ...filtersFormatted,
            offset,
          }
        });
      }
      const res = await Ajax.get({
        url: `${routeBase}api/${route}`,
        token: true,
        queryString: {
          list: true,
          count: true,
          ...queryStringEnrichValue,
          ...filtersFormatted,
          offset,
        }
      });
      if (isEqual(this.state.filters, filters)) {
        const setState = { fetching: false };
        if (res.type === 'success') {
          setState.data = res.list;
          setState.rowCount = res.count;
          setState.filters = { ...this.state.filters, offset };
        } else {
          Notify.error(res.message);
        }
        if (afterFetchPage) {
          afterFetchPage(setState);
        }
        this.setState(setState);
      }
    });
  }

  initColumnsOrder(props) {
    const { columns, allowEdit, allowVersion } = props;
    const orders = [];
    if (allowVersion) {
      orders.push({ field: '__version', orginalIdx: 0 });
    }
    if (allowEdit && !allowVersion) {
      orders.push({ field: '__actions', orginalIdx: 0 });
    }
    if (allowEdit && allowVersion) {
      orders.push({ field: '__actions', orginalIdx: 1 });
    }
    return orders.concat(columns.map((col, idx) => ({ field: col.field, orginalIdx: allowEdit && allowVersion ? idx + 2 : (allowEdit || allowVersion ? idx + 1 : idx) })));
  }

  getColumns() {
    const { columns, allowEdit, allowVersion } = this.props;
    const { fetching, columnsOrder } = this.state;
    const allColumns = [...columns];
    if (allowEdit) {
      allColumns.unshift({
        field: '__actions', headerName: 'Edition', width: 60, sortable: false, renderCell: ({ row }) => (
          <Tooltip title="Editer la ligne">
            <span>
              <IconButton onClick={() => this.handleAction('edit', row)} disabled={fetching}>
                <ModeEditIcon />
              </IconButton>
            </span>
          </Tooltip>
        )
      });
    }
    if (allowVersion) {
      allColumns.unshift({
        field: '__version', headerName: 'Versions', width: 60, sortable: false, renderCell: ({ row }) => (
          <Tooltip title="Version de la ligne">
            <span>
              <IconButton color="primary" onClick={() => this.showVersion(row.id)}>
                <RestoreIcon />
              </IconButton>
            </span>
          </Tooltip>
        )
      });
    }
    return columnsOrder.map(col => allColumns[col.orginalIdx]);
  }

  getVersionColumns() {
    const { columns, allowVersion } = this.props;
    if (allowVersion) {
      return [
        { field: 'version_id', headerName: 'Version ID', width: 90 },
        { field: 'version_date', headerName: 'Version Date', width: 200 },
        ...columns.filter(col => !!col.version),
      ];
    }
    return [];
  }

  cleanVersionRows(rows) {
    const { columns } = this.props;
    return rows.map((row, idx) => {
      const newRow = { ...row, __version_diff: [] };
      if (newRow.version_date) {
        newRow.version_date = dateTimeEnToDateTimeFr({ value: newRow.version_date });
      }
      if (newRow.version_id && newRow.version_id >= Number.MAX_SAFE_INTEGER) {
        newRow.version_id = 'Current';
      }
      if (idx < rows.length - 1) {
        const prevRow = rows[idx + 1];
        forEach(newRow, (value, key) => {
          const column = columns.find(col => col.field === key);
          if (
            key !== 'version_date' &&
            key !== 'version_type' &&
            key !== 'version_id' &&
            key !== '__version_diff' &&
            (column && column.valueGetter ? column.valueGetter({ value, row: newRow }) !== column.valueGetter({ value: prevRow[key], row: prevRow }) : value !== prevRow[key])
          ) {
            newRow.__version_diff.push(key);
          }
        });
      }
      return newRow;
    });
  }

  async toggleEditModal(isOpen, item) {
    let editItem = item;
    let editPanelOpen = isOpen;
    if (editPanelOpen && !isNil(editItem)) {
      const { rowKey, route, routeBase } = this.props;
      const key = editItem[rowKey || 'id'];
      if (key) {
        const res = await Ajax.get({
          url: `${routeBase}api/${route}/${key}`,
          token: true,
        });
        if (res.type === 'success') {
          editItem = res.item;
        } else {
          editPanelOpen = false;
          Notify.error(res.message);
        }
      }
    }
    this.setState({ editPanelOpen, editItem });
  }

  /**
   * HANDLES
   */
  handleChangeFilters(filters) {
    if (!isEqual(filters, this.state.filters)) {
      this.setState(prev => {
        const newFilters = { ...prev.filters, ...filters };
        forEach(newFilters, (value, key) => {
          if (value === undefined) {
            delete newFilters[key];
          }
        });
        return { filters: newFilters };
      }, () => this.fetchPage(0));
    }
  }

  handleVisibilityChange(columnsVisibility) {
    this.setState({ columnsVisibility }, this.setHashedState);
  }

  handleColumnsOrderChange(field, source, destination) {
    const orders = [...this.state.columnsOrder];
    array_move(orders, source, destination);
    this.setState({ columnsOrder: orders }, this.setHashedState);
  }

  handleSaveConfiguration(confName) {
    const { title, confId } = this.props;
    const urlParams = new URLSearchParams(window.location.search);
    const id = urlParams.get('hashedState');
    if (id) {
      const hash = window.sessionStorage.getItem(id);
      if (hash) {
        let hashedList = window.localStorage.getItem(btoa(`hash_${title || confId}`));
        let list = [];
        if (hashedList) {
          list = JSON.parse(atob(hashedList));
        }
        if (list.indexOf(confName.trim()) === -1) {
          list.push(confName.trim());
        }
        hashedList = btoa(JSON.stringify(list));
        window.localStorage.setItem(btoa(`hash_${title || confId}`), hashedList);
        window.localStorage.setItem(btoa(`hash_${confName.trim()}`), hash);
      }
    }
  }

  handleLoadConfiguration(confName, loadFilters = true) {
    const hash = window.localStorage.getItem(btoa(`hash_${confName.trim()}`));
    const state = JSON.parse(atob(hash));
    const newState = {
      columnsOrder: state.columnsOrder,
      columnsVisibility: state.columnsVisibility,
    };
    if (loadFilters) {
      newState.filters = { ...DEFAULT_PARAMS, order: this.props.rowKey, ...state.filters };
    }
    this.setState(newState, () => this.fetchPage(0));
  }

  handleDeleteConfiguration(confName) {
    const { title, confId } = this.props;
    window.localStorage.removeItem(btoa(`hash_${confName.trim()}`));
    let hashedList = window.localStorage.getItem(btoa(`hash_${title || confId}`));
    let list = [];
    if (hashedList) {
      list = JSON.parse(atob(hashedList));
      const idx = list.findIndex(name => name === confName);
      if (idx > -1) {
        list.splice(idx, 1);
        hashedList = btoa(JSON.stringify(list));
        window.localStorage.setItem(btoa(`hash_${title || confId}`), hashedList);
      }
    }
  }

  getConfigurations() {
    const { title, confId } = this.props;
    let hashedList = window.localStorage.getItem(btoa(`hash_${title || confId}`));
    if (!hashedList) {
      return [];
    }
    return JSON.parse(atob(hashedList));
  }

  handleClearFilters() {
    this.setState({ filters: { ...DEFAULT_PARAMS, order: this.props.rowKey } }, () => this.fetchPage(0));
  }

  handleAction(action, params) {
    const { onAddAction, onEditAction } = this.props;
    if (action === 'add') {
      return onAddAction ? onAddAction(params) : this.toggleEditModal(true);
    } else if (action === 'edit' && !isNil(params)) {
      return onEditAction ? onEditAction(params) : this.toggleEditModal(true, params);
    } else if (action === 'delete' && params.length > 0) {
      return this.applyDeleteSelection(params);
    } else if (action === 'export') {
      return this.applyExport(params);
    }
  }

  handleSetSelectedRows(selectedRows) {
    this.setState({ selectedRows })
  }

  handleValidateEdition(body, isNew) {
    if (isNew) {
      this.applyAddItem(body);
    } else {
      this.applyEditItem(body);
    }
  }

  async showVersion(id) {
    const { route, routeBase, routeVersion } = this.props;
    const routeUrl = routeVersion || `${route}-version`;
    const result = await Ajax.get({ url: `${routeBase}api/${routeUrl}/${id}`, token: true });
    if (result.type === 'success') {
      this.setState({ version: this.cleanVersionRows(result.rows) }, () => {
        const interval = setInterval(() => {
          if (!this.state.version || this.state.version.length === 0) {
            clearInterval(interval);
          } else if (this.state.version.length > 0 && this.versionGridRef && this.versionGridRef.current) {
            const gridEl = this.versionGridRef.current.querySelector('.MuiDataGrid-virtualScroller');
            if (!this.dragscroll) {
              this.dragscroll = new DragScroll(gridEl);
            } else {
              this.dragscroll.initEl(gridEl);
            }
            clearInterval(interval);
          }
        }, 10);
      });
    } else {
      Notify.error('Une erreur est survenue');
    }
  }

  closeVersions() {
    this.setState({ version: null });
    if (this.dragscroll) {
      this.dragscroll.willUnmount();
      this.dragscroll = null;
    }
  }

  /**
   * AJAX
   */
  async applyEditItem(body) {
    const { onApplyChange } = this.props;
    this.setState({ working: true });
    const { route, rowKey, routeBase } = this.props;
    const { editItem } = this.state;
    const id = body[rowKey];
    const res = await Ajax.patch({ url: `${routeBase}api/${route}/${id}`, body, token: true });
    if (res.type === 'success') {
      Notify.success(`{${id}} modifié avec succès`);
      this.toggleEditModal(false, editItem);
      this.fetchPage(0);
      if (onApplyChange) {
        onApplyChange();
      }
    } else {
      Notify.error(res.message);
    }
    this.setState({ working: false });
  }

  async applyAddItem(body) {
    const { onApplyChange } = this.props;
    this.setState({ working: true });
    const { route, routeBase } = this.props;
    const res = await Ajax.post({ url: `${routeBase}api/${route}`, body, token: true });
    if (res.type === 'success') {
      Notify.success('Element ajouté avec succès');
      this.toggleEditModal(false);
      this.fetchPage(0);
      if (onApplyChange) {
        onApplyChange();
      }
    } else {
      Notify.error(res.message);
    }
    this.setState({ working: false });
  }

  async applyDeleteSelection(selectedRows) {
    const { onApplyChange, rowKey } = this.props;
    const ids = selectedRows.map(row => row[rowKey]);
    this.setState({ working: true });
    const { route, routeBase } = this.props;
    const res = await Ajax.delete({ url: `${routeBase}api/${route}`, queryString: { id: ids }, token: true });
    if (res.type === 'success') {
      const multi = ids.length > 1 ? 's' : '';
      Notify.success(`Element${multi} supprimé${multi} avec succès`);
      this.fetchPage(0);
      if (onApplyChange) {
        onApplyChange();
      }
    } else {
      Notify.error(res.message);
    }
    this.setState({ working: false });
  }

  async applyExport(params) {
    this.setState({ working: true });
    const { route, filterId, rowKey, routeBase } = this.props;
    let queryString = {
      file: params.type,
      separator: params.separator,
    };
    if (params.rows) {
      queryString[filterId] = params.rows.map(row => row[rowKey]);
      queryString.limit = -1;
    } else {
      const { filters } = this.state;
      queryString = {
        ...queryString,
        ...filters,
        limit: -1,
      };
    }
    const res = await Ajax.get({
      url: `${routeBase}api/${route}-export`,
      queryString,
      token: true
    });
    if (res.type === 'success' && res.content && res.content !== 'false') {
      Download(res.content, `${routeBase}export-${route}-${moment().format('YYYY-MM-DD_HH-mm-ss')}.${params.type.toLowerCase()}`, params.type);
      Notify.success('Opération effectuée avec succès');
    } else {
      Notify.error(res.message || 'Erreur lors de la création du fichier');
    }
    this.setState({ working: false });
  }

  /**
   * RENDERS
   */
  renderVersions() {
    const rows = this.state.version || [];
    const { classes } = this.props;
    const columns = this.getVersionColumns();
    return (
      <Dialog maxWidth="xl" sx={{ height: '100%' }} fullWidth onClose={this.closeVersions} open={rows.length > 0}>
        <DialogContent sx={{ minHeight: "33rem" }}>
          <div className={classes.wrapper}>
            <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ marginBottom: 1.5 }}>
              <Typography variant="h4" sx={{ fontSize: { xs: '1rem', sm: '1rem', md: '1.6rem' } }}>Liste des versions</Typography>
              <IconButton color="primary" onClick={this.closeVersions}>
                <CloseIcon />
              </IconButton>
            </Stack>
            <span ref={this.versionGridRef} style={{ height: '100%' }} className="MuiDataGrid-dragscroll">
              <MuiDataGrid
                sx={{
                  maxHeight: '100%',
                  '& .MuiDataGrid-main': {
                    minHeight: '33rem'
                  },
                  '& .MuiDataGrid-footerContainer': {
                    display: 'none',
                  },
                  '& .MuiDataGrid-main .MuiDataGrid-cell.__version_diff': {
                    boxShadow: 'inset 0px 0px 0px 1.5px #21928f',
                    position: 'relative',
                    '&:after': {
                      content: "''",
                      position: 'absolute',
                      pointerEvents: 'none',
                      width: 15,
                      height: 15,
                      background: '#21928f',
                      transform: 'rotate(45deg)',
                      bottom: -7,
                      left: 'calc(50% - 7px)',
                    },
                  },
                  '& .MuiDataGrid-main .MuiDataGrid-cell.__join_field ': {
                    backgroundColor: '#F2F2F8',
                    color: '#666',
                  }
                }}
                rows={rows}
                rowCount={rows.length}
                disableSelectionOnClick
                disableDensitySelector
                disableExtendRowFullWidth
                disableColumnMenu
                disableColumnFilter
                getRowId={row => row.version_id}
                columns={columns}
                getCellClassName={({ row, field, colDef }) => {
                  let className = '';
                  if (row.__version_diff && row.__version_diff.indexOf(field) !== -1) {
                    className += ' __version_diff';
                  }
                  if (colDef.joinField) {
                    className += ' __join_field';
                  }
                  return className;
                }}
              />
            </span>
          </div>
        </DialogContent>
      </Dialog>
    );
  }

  renderGrid(columns) {
    const { classes, rowKey, hideSelectionCheckbox } = this.props;
    const { data, rowCount, filters, selectedRows, fetching, columnsVisibility } = this.state;
    return (
      <div className={classes.listContent}>
        <DataGrid
          data={data}
          rowKey={rowKey}
          columns={columns}
          columnsVisibility={columnsVisibility}
          count={rowCount}
          params={filters}
          selectedRows={selectedRows}
          loading={fetching}
          onPageChange={p => this.fetchPage(p)}
          onChangeParams={this.handleChangeFilters}
          onChangeSelectedRows={!hideSelectionCheckbox ? this.handleSetSelectedRows : undefined}
        />
      </div>
    );
  }

  renderEditPanel() {
    const { EditModalComponent, rowKey, requiredFields, options, dialogProps, maxSize } = this.props;
    const { editPanelOpen, editItem, working } = this.state;

    if (!EditModalComponent || !editPanelOpen) {
      return null;
    }
    return (
      <EditModalComponent
        open={editPanelOpen}
        maxWidth={maxSize}
        dialogProps={dialogProps}
        item={editItem}
        rowKey={rowKey}
        requiredFields={requiredFields}
        mode={!isNil(editItem) ? EDITMODAL_MODES.EDIT : EDITMODAL_MODES.ADD}
        working={working}
        options={options}
        onCancel={() => this.toggleEditModal(false, editItem)}
        onValidate={this.handleValidateEdition}
      />
    );
  }

  render() {
    const {
      classes,
      allowAdd,
      allowDelete,
      allowExport,
      filterParams,
      actions,
      rowActions,
      filterActions,
      searchKey,
      searchLabel,
      searchPlaceholder,
      hideRowActions,
      title,
    } = this.props;
    const { rowCount, filters, selectedRows, columnsVisibility } = this.state;
    const columns = this.getColumns();
    return (
      <div className={classes.wrapper}>
        <Typography variant="h4" sx={{ fontSize: { xs: '1rem', sm: '1rem', md: '1.6rem' } }}>{title}</Typography>
        <DataGridToolbar
          rowCount={rowCount}
          filters={filters}
          selectedRows={selectedRows}
          filterParams={filterParams}
          onAction={this.handleAction}
          onChangeFilters={this.handleChangeFilters}
          onClearFilters={this.handleClearFilters}
          searchKey={searchKey}
          searchLabel={searchLabel}
          searchPlaceholder={searchPlaceholder}
          additionnalActions={actions}
          additionnalRowActions={rowActions}
          additionnalFilterActions={filterActions}
          allowAdd={allowAdd}
          allowDelete={allowDelete}
          allowExport={allowExport}
          hideRowActions={hideRowActions}
          columns={columns}
          columnsVisibility={columnsVisibility}
          onVisibilityChange={this.handleVisibilityChange}
          onColumnsOrderChange={this.handleColumnsOrderChange}
          onSaveConfiguration={!this.props.disabledHashedState ? this.handleSaveConfiguration : null}
          onLoadConfiguration={!this.props.disabledHashedState ? this.handleLoadConfiguration : null}
          onDeleteConfiguration={!this.props.disabledHashedState ? this.handleDeleteConfiguration : null}
          getConfigurations={!this.props.disabledHashedState ? this.getConfigurations : null}
        />
        <div className={title ? classes.listWrapper : classes.listWrapperNotitle}>
          {this.renderGrid(columns)}
        </div>
        {this.renderEditPanel()}
        {this.renderVersions()}
      </div>
    );
  }
}

EditableList.propTypes = {
  classes: PropTypes.object.isRequired,
  // Main
  route: PropTypes.string.isRequired,
  routeBase: PropTypes.string,
  rowKey: PropTypes.string.isRequired,
  filterId: PropTypes.string,
  columns: PropTypes.array.isRequired,
  filterParams: PropTypes.array,
  EditModalComponent: PropTypes.object,
  requiredFields: PropTypes.oneOfType([PropTypes.array, PropTypes.object]), // Either ['fieldname'] or { [EDITMODAL_MODES.ADD]: ['fieldname], [EDITMODAL_MODES.EDIT]: ['fieldname']}
  actions: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.func,
  ]),
  rowActions: PropTypes.array,
  filterActions: PropTypes.array,
  hideSelectionCheckbox: PropTypes.bool,
  defaultParams: PropTypes.object,
  dialogProps: PropTypes.object,
  // Restrictions
  allowAdd: PropTypes.bool,
  allowEdit: PropTypes.bool,
  allowDelete: PropTypes.bool,
  allowExport: PropTypes.bool,
  allowVersion: PropTypes.bool,
  // Search
  searchKey: PropTypes.string,
  searchLabel: PropTypes.string,
  searchPlaceholder: PropTypes.string,
  disabledHashedState: PropTypes.bool,
  // Others
  options: PropTypes.object,
  hideRowActions: PropTypes.bool,
  refreshToken: PropTypes.number,
  title: PropTypes.string,
  maxSize: PropTypes.string,
  // Func
  queryStringEnrich: PropTypes.func, // return object with queryString values
  onApplyChange: PropTypes.func,
  beforeFetchPage: PropTypes.func,
  afterFetchPage: PropTypes.func,
  onAddAction: PropTypes.func,
  onEditAction: PropTypes.func,
};

export default withStyles(styles)(EditableList);
